import React, { useEffect, useState } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { Card, CardBody, Modal, ModalBody, ModalHeader } from "reactstrap";
import {
  callErrorToast,
  modalSettingsDefault,
  orderStatus,
} from "../../utilities";
import { useContext } from "react";
import { DetailsOrderContext } from "./providers/Details";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLoader } from "@awesome.me/kit-c1b85ff10f/icons/classic/light";
import OrderDetails from "../OrderDetails";
import moment from "moment";
import { Loader } from "../../Components/common/Loader";
import Delete from "./Delete";
import Process from "./Process";
import { ConfirmOrdersProvider } from "./providers/Confirm";
import { DeleteOrdersProvider } from "./providers/Delete";
import Confirm from "./Confirm";
import { QuickView as AddressBookQuickView } from "../AddressBook/QuickView";
import Rate from "./Rate";
import { useOidcAccessToken } from "@axa-fr/react-oidc";
import { IndexOrderDetailsProvider } from "../OrderDetails/providers/Index";
import { ProcessOrderProvider } from "./providers/Process";

export const Details = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { accessTokenPayload } = useOidcAccessToken();

  const {
    order,
    orderError,
    orderLoader,
    getOrder,
    cashOnDeliveryMethod,
    cashOnDeliveryMethodError,
  } = useContext(DetailsOrderContext);

  const [modalSettings, setModalSettings] = useState({ modalSettingsDefault });

  useEffect(() => {
    if (orderError) {
      callErrorToast(orderError);
    }
  }, [orderError]);

  useEffect(() => {
    if (cashOnDeliveryMethodError) {
      callErrorToast(cashOnDeliveryMethodError);
    }
  }, [cashOnDeliveryMethodError]);

  useEffect(() => {
    if (id) {
      getOrder();
    }
  }, []);

  const orderLastStatus = orderStatus.find(
    (elem) => elem.value === order?.lastStatus
  );

  const shipment = order?.shipments ? order?.shipments[0] : null;
  return (
    <React.Fragment>
      <div className="content" id="container">
        {orderLoader ? <Loader show={orderLoader} /> : ""}
        <div className="row justify-content-end mb-2">
          <div className="col-3">
            {orderLastStatus && (
              <div
                style={{
                  backgroundColor:
                    shipment?.lastStatusColor || orderLastStatus.color,
                }}
                className={`status-container`}
              >
                <span className={`status-name-small`}>
                  {shipment?.lastStatusName || orderLastStatus.label}
                </span>
              </div>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col">
            <h5>Informazioni generali</h5>
            <Card>
              <CardBody>
                <div className="row">
                  <div className="col">
                    <label className="fw-semibold d-block">Rif.Interno</label>
                    <label>{order?.internalReference || "-"}</label>
                  </div>
                  <div className="col">
                    <label className="fw-semibold d-block">Rif.Esterno</label>
                    <label>{order?.externalReference || "-"}</label>
                  </div>
                  <div className="col">
                    <label className="fw-semibold d-block">Rif.Logistica</label>
                    <label>{order?.logisticReference || "-"}</label>
                  </div>
                  <div className="col">
                    <label className="fw-semibold d-block">Rif.Store</label>
                    <label>{order?.storeReference || "-"}</label>
                  </div>
                </div>
              </CardBody>
            </Card>
            <h5>Informazioni aggiuntive</h5>
            <Card>
              <CardBody>
                <div className="row">
                  <div className="col">
                    <label className="fw-semibold d-block">Valore merce</label>
                    <label>
                      {order?.goodsValue
                        ? `${order?.goodsValue.toFixed(2)} ${order?.currency}`
                        : "-"}
                    </label>
                  </div>
                  <div className="col">
                    <label className="fw-semibold d-block">
                      Data di creazione
                    </label>
                    <label>
                      {order?.creationDate
                        ? moment(order.creationDate).format("DD/MM/YYYY HH:mm")
                        : "-"}
                    </label>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col">
                    <label className="fw-semibold d-block">
                      Valore contrassegno
                    </label>
                    <label>
                      {order?.cashOnDeliveryValue
                        ? `${order?.cashOnDeliveryValue.toFixed(2)} ${
                            order?.currency
                          }`
                        : "-"}
                    </label>
                  </div>
                  {order?.cashOnDeliveryValue && (
                    <div className="col">
                      <label className="fw-semibold d-block">
                        Metodo incasso contrassegno
                      </label>
                      <label>
                        {cashOnDeliveryMethod?.name &&
                          `${cashOnDeliveryMethod.name}`}
                      </label>
                    </div>
                  )}
                </div>
                <div className="row mt-3">
                  <div className="col">
                    <label className="fw-semibold d-block">
                      Valore assicurazione
                    </label>
                    <label>
                      {order?.insuranceValue
                        ? `${order?.insuranceValue.toFixed(2)} ${
                            order?.currency
                          }`
                        : "-"}
                    </label>
                  </div>
                  <div className="col">
                    <label className="fw-semibold d-block">Sorgente</label>
                    <label>{order?.sourceType || "-"}</label>
                  </div>
                </div>

                <div className="row mt-3">
                  {shipment && (
                    <div className="col">
                      <label className="fw-semibold d-block">Spedizione</label>
                      <Link
                        target="_blank"
                        to={"/shipments/details/" + shipment.id}
                      >
                        {shipment.internalReference}
                      </Link>
                    </div>
                  )}
                  <div className="col">
                    <label className="fw-semibold d-block">
                      Costo spedizione
                    </label>
                    <label>
                      {order?.shippingCost
                        ? `${order?.shippingCost.toFixed(2)} ${order?.currency}`
                        : "-"}
                    </label>
                  </div>
                </div>
                <div className="row mt-3">
                  {order?.paymentMethod && (
                    <div className="col">
                      <label className="fw-semibold d-block">
                        Metodo di pagamento
                      </label>
                      <label>{order?.paymentMethod}</label>
                    </div>
                  )}
                </div>
              </CardBody>
            </Card>
          </div>
          <div className="col">
            <h5>Destinatario</h5>
            <Card>
              <CardBody>
                <AddressBookQuickView
                  defaultContact={order?.delivery}
                  showSelect={false}
                />
                <div className="row mt-3">
                  <div className="col">
                    {order?.delivery?.id === order?.billing?.id ? (
                      <label className="fw-semibold d-block">
                        L'indirizzo di fatturazione corrisponde a quello di
                        destinazione
                      </label>
                    ) : (
                      <>
                        <h5>Indirizzo di fatturazione</h5>
                        <AddressBookQuickView
                          defaultContact={order?.billing}
                          showSelect={false}
                        />
                      </>
                    )}
                  </div>
                </div>
              </CardBody>
            </Card>
          </div>
        </div>
        {order?.id && (
          <div className="row mt-3">
            <div className="col">
              <h5>Prodotti</h5>
              <Card>
                <CardBody>
                  <IndexOrderDetailsProvider parentId={order?.id}>
                    <OrderDetails
                      parentId={order?.id}
                      viewOnly={true}
                      orderStatus={order?.lastStatus}
                    />
                  </IndexOrderDetailsProvider>

                  <div className="row">
                    <div className="col"></div>
                    <div className="col"></div>
                    <div className="col"></div>
                    <div className="col"></div>
                    <div className="col">
                      <label className="fw-semibold d-block">Peso totale</label>
                      <label>
                        {order?.totalWeight
                          ? `${order?.totalWeight.toFixed(3)} kg`
                          : "-"}
                      </label>
                    </div>
                    <div className="col"></div>
                    <div className="col"></div>
                    <div className="col">
                      <label className="fw-semibold d-block">
                        Prezzo totale
                      </label>
                      <label>
                        {order?.totalPrice
                          ? `${order?.totalPrice.toFixed(2)} ${order?.currency}`
                          : "-"}
                      </label>
                    </div>
                    <div className="col">
                      <label className="fw-semibold d-block">
                        Prezzo totale (+ IVA)
                      </label>
                      <label>
                        {order?.totalPrice
                          ? `${((order?.totalPrice || 0) * 1.22).toFixed(2)} ${
                              order?.currency
                            }`
                          : "-"}
                      </label>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </div>
          </div>
        )}
        <div className="row">
          <div className="col text-end">
            {order?.id &&
              order?.lastStatus === "PROCESSING" &&
              accessTokenPayload.owner ===
                "7f8625b9-973b-4c66-80af-346b49cde2e7" && (
                <button
                  className="btn btn-outline-dark btn-sm btn btn-light ms-2"
                  onClick={() => {
                    setModalSettings({
                      ...modalSettings,
                      title: "Crea spedizione",
                      icon: "ph-plus",
                      open: !modalSettings.open,
                      action: "createShipment",
                      order: order,
                      size: "xl",
                    });
                  }}
                >
                  <i className="ph-plus me-2"></i>
                  Crea spedizione
                </button>
              )}

            {order?.id && order?.lastStatus === "CONFIRMED" && (
              <>
                {accessTokenPayload.owner ===
                  "7f8625b9-973b-4c66-80af-346b49cde2e7" && (
                  <button
                    className="btn btn-outline-primary btn-sm btn btn-light"
                    onClick={() => {
                      setModalSettings({
                        ...modalSettings,
                        title: "Lavora ordine",
                        icon: "ph-check",
                        open: !modalSettings.open,
                        action: "process",
                        orders: [order?.id],
                      });
                    }}
                  >
                    <i className="ph-check me-2"></i>
                    Lavora
                  </button>
                )}
                <button
                  className="btn btn-outline-danger btn-sm btn btn-light ms-2"
                  onClick={() => {
                    setModalSettings({
                      ...modalSettings,
                      title: "Elimina ordine",
                      icon: "ph-trash",
                      open: !modalSettings.open,
                      action: "delete",
                      orders: [order],
                    });
                  }}
                >
                  <i className="ph-trash me-2"></i>
                  Elimina
                </button>
              </>
            )}

            {order?.id && order?.lastStatus === "DRAFT" && (
              <React.Fragment>
                <button
                  className="btn btn-outline-danger btn-sm btn btn-light ms-2"
                  onClick={() => {
                    setModalSettings({
                      ...modalSettings,
                      title: "Elimina ordine",
                      icon: "ph-check",
                      open: !modalSettings.open,
                      action: "delete",
                      orders: [order],
                    });
                  }}
                >
                  <i className="ph-trash me-2"></i>
                  Elimina
                </button>
                <button
                  className="btn btn-outline-success btn-sm btn btn-light ms-2"
                  onClick={() => {
                    setModalSettings({
                      ...modalSettings,
                      title: "Conferma ordine",
                      icon: "ph-check",
                      open: !modalSettings.open,
                      action: "confirm",
                      orders: [order?.id],
                    });
                  }}
                >
                  <i className="ph-check me-2"></i>
                  Conferma
                </button>
              </React.Fragment>
            )}
          </div>
        </div>
      </div>

      <Modal
        isOpen={modalSettings.open}
        className="modal-content"
        size={modalSettings.size || "md"}
      >
        <ModalHeader
          toggle={() => {
            setModalSettings({
              modalSettingsDefault,
              open: !modalSettings.open,
            });
          }}
        >
          <span className="modal-title">
            <i className={modalSettings.icon + " me-2"}></i>
            {modalSettings.title}
          </span>
        </ModalHeader>
        <ModalBody>
          {modalSettings.action === "confirm" && (
            <ConfirmOrdersProvider
              data={modalSettings.orders}
              callback={() => {
                setModalSettings({
                  modalSettingsDefault,
                  open: !modalSettings.open,
                });
                navigate("/orders");
              }}
            >
              <Confirm />
            </ConfirmOrdersProvider>
          )}
          {modalSettings.action === "delete" && (
            <DeleteOrdersProvider
              data={modalSettings.orders}
              callback={() => {
                setModalSettings({
                  modalSettingsDefault,
                  open: !modalSettings.open,
                });
                navigate("/orders");
              }}
            >
              <Delete />
            </DeleteOrdersProvider>
          )}
          {/* {modalSettings.action === "delete" && (
            <DeleteOrderProvider
              data={modalSettings.orders}
              unmountCallback={() => {
                setModalSettings({
                  modalSettingsDefault,
                  open: !modalSettings.open,
                });
                navigate("/orders");
              }}
            >
              <Delete />
            </DeleteOrderProvider>
          )}
          {modalSettings.action === "confirm" && (
            <ConfirmOrderProvider
              data={modalSettings.orders}
              unmountCallback={() => {
                setModalSettings({
                  modalSettingsDefault,
                  open: !modalSettings.open,
                });
                getOrder();
              }}
            >
              <Confirm />
            </ConfirmOrderProvider>
          )} */}

          {modalSettings.action === "process" && (
            <ProcessOrderProvider
              data={modalSettings.orders}
              unmountCallback={() => {
                setModalSettings({
                  modalSettingsDefault,
                  open: !modalSettings.open,
                });
                getOrder();
              }}
            >
              <Process />
            </ProcessOrderProvider>
          )}
          {modalSettings.action === "createShipment" && (
            <Rate
              callback={() => {
                setModalSettings({
                  modalSettingsDefault,
                  open: !modalSettings.open,
                });
                getOrder();
              }}
            />
          )}
          {/* {context?.modalSettings.action === "createShipment" && (
            <LogisticRateProvider
              callback={() => {
                "chiudi modal";
              }}
              parentId={context?.order?.id}
            >
              <Rate service="logistic" />
            </LogisticRateProvider>
          )} */}
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};
