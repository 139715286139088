import React from "react";
import Select from "react-select";
import { useStyle } from "./utilities";

const SelectAlbx = ({
  name,
  className,
  onChange,
  options = [],
  value,
  placeholder,
  isMulti = false,
  optionStyle,
  optionValue = "value",
  optionLabel = "label",
  closeMenuOnSelect = true,
  isClearable = false,
  isDisabled = false,
}) => {
  const style = useStyle();
  return (
    <Select
      name={name}
      isClearable={isClearable}
      isDisabled={isDisabled}
      onChange={onChange}
      className={className}
      options={options}
      value={value}
      getOptionValue={(option) => option[optionValue]}
      getOptionLabel={(option) => option[optionLabel]}
      placeholder={placeholder}
      closeMenuOnSelect={closeMenuOnSelect}
      isMulti={isMulti}
      formatOptionLabel={(option) =>
        optionStyle
          ? optionStyle(option, optionValue, optionLabel)
          : option[optionLabel]
      }
      styles={style}
    />
  );
};

export default SelectAlbx;
