import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useRef,
} from "react";

import { callErrorToast } from "../../../utilities";
import { ShipmentsService } from "../../../services/shipment/shipments";

import { useParams } from "react-router-dom";

export const DDTContext = createContext();

const shipmentsService = new ShipmentsService();

const DDTProvider = ({ children }) => {
  const { id } = useParams();

  const [shipment, setShipment] = useState();
  const [shipmentLoader, setShipmentLoader] = useState(true);
  const [shipmentError, setShipmentError] = useState();

  const prevShipmentError = useRef();

  const getShipment = () => {
    shipmentsService
      .get(id)
      .then((res) => {
        setShipmentLoader(false);
        setShipment(res.data);
      })
      .catch((err) => {
        setShipmentLoader(false);
        setShipmentError(err);
      });
  };

  useEffect(() => {
    if (id) {
      getShipment();
    }
  }, []);

  useEffect(() => {
    if (
      shipmentError &&
      JSON.stringify(prevShipmentError.current) !==
        JSON.stringify(shipmentError)
    ) {
      callErrorToast(shipmentError);
    }
  }, [shipmentError]);

  return (
    <DDTContext.Provider
      value={{
        shipment,
        setShipment,
        shipmentLoader,
      }}
    >
      {children}
    </DDTContext.Provider>
  );
};

const useDDTContext = () => {
  return useContext(DDTContext);
};

export { DDTProvider, useDDTContext };
