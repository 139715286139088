import React, { useState, useEffect, useContext } from "react";
import { useOidcAccessToken } from "@axa-fr/react-oidc";
import { useParams, useNavigate } from "react-router-dom";
import BreadcrumbItem from "../../Components/common/Breadcrumb";
import {
  CreateEditOrderContext,
  useCreateEditOrderContext,
} from "./providers/CreateEdit";
import {
  Card,
  CardBody,
  CardFooter,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import { Loader } from "../../Components/common/Loader";
import AsyncSelect from "../../Components/common/AsyncSelect/AsyncSelect";
import Select from "react-select";
import {
  getAllCashOnDeliveryCollectionMethods,
  getAllStoreIntegrations,
  getAllWarehouses,
} from "../../utilities/asyncSelectCallAPI";
import {
  getSourceIntegrationOptionStyle,
  getStoreIntegrationOptionStyle,
  getWarehouseOptionStyle,
} from "../../Components/common/AsyncSelect/utilities";
import InputBox from "../../Components/common/InputBox";
import { orderNormalizer } from "./utilities";
import {
  callErrorToast,
  modalSettingsDefault,
  orderBykey,
} from "../../utilities";
import { currenciesList } from "../../constants";
import OrderDetails from "../OrderDetails";
import { CreateEdit as CreateEditContact } from "../Contacts/CreateEdit";
import { CreateEditContactProvider } from "../Contacts/providers/CreateEdit";
import { QuickView as AddressBookQuickView } from "../AddressBook/QuickView";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faPlus } from "@awesome.me/kit-c1b85ff10f/icons/classic/light";
import { DeleteOrdersProvider } from "./providers/Delete";
import Delete from "./Delete";
import { ConfirmOrdersProvider } from "./providers/Confirm";
import Confirm from "./Confirm";
import Rate from "./Rate";
import { LogisticRateProvider } from "../Rate/providers/Logistic";
import { IndexOrderDetailsProvider } from "../OrderDetails/providers/Index";
// import Rate from "../Rate";
// import { LogisticRateProvider } from "../Rate/providers/Logistic";

export const CreateEdit = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const context = useCreateEditOrderContext();

  const handleCheckboxChange = (selector, value) => {
    context.setNewBillingContactEnabled(!context.newBillingContactEnabled);

    if (context.newBillingContactEnabled) {
      const order = { ...context.order, billing: context.order?.delivery };
      context.editOrder(order);
    }
  };

  const handleAsyncSelectChange = (selector, value) => {
    const order = {
      ...context.order,
      [selector]: value,
    };

    context.editOrder(order);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    const order = {
      ...context.order,
      [name]: value,
    };

    context.editOrder(order);
    context.removeError(name);
  };

  const handleSelectChange = (value, event) => {
    const order = {
      ...context.order,
      [event.name]: value,
    };

    context.editOrder(order);
  };

  useEffect(() => {
    if (!id) {
      if (context.autosave) {
        context.createOrder();
      }
    } else {
      context.getOrder();
    }
  }, []);

  return (
    <React.Fragment>
      <BreadcrumbItem
        title="Ordini"
        breadcrumbItem={id ? "Modifica ordine" : "Nuovo ordine"}
        link={`/orders`}
      />
      <div className="content" id="container">
        {context?.orderLoader ? <Loader show={context?.orderLoader} /> : ""}
        <div className="row">
          <div className="col">
            <h5>Informazioni generali</h5>
            <Card>
              <CardBody>
                <div className="row">
                  <div className="col">
                    <label className="form-label">Magazzino:</label>
                    <AsyncSelect
                      name="warehouse"
                      optionValue="id"
                      optionLabel="name"
                      value={context?.order?.warehouse}
                      onSearch={handleAsyncSelectChange}
                      loadOptions={(inputValue, pagination) => {
                        return getAllWarehouses(inputValue, pagination, {
                          orderBy: "asc",
                          selector: "name",
                        });
                      }}
                      optionStyle={getWarehouseOptionStyle}
                      isClearable={false}
                    />
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col">
                    <label className="form-label">Rif. Cliente:</label>
                    <InputBox
                      name="externalReference"
                      value={context.order?.externalReference || ""}
                      placeholder={"EXTREF123"}
                      type="text"
                      onChange={handleInputChange}
                      error={
                        context.orderError?.response?.data?.externalReference
                      }
                    />
                  </div>
                  <div className="col">
                    <label className="form-label">Rif. Logistica:</label>
                    <InputBox
                      name="logisticReference"
                      value={context.order?.logisticReference || ""}
                      placeholder={"EXTREF123"}
                      type="text"
                      readOnly={true}
                      disabled={true}
                    />
                  </div>
                  <div className="col">
                    <label className="form-label">Rif. Allabox:</label>
                    <InputBox
                      name="internalReference"
                      value={context.order?.internalReference || ""}
                      placeholder={"EXTREF123"}
                      type="text"
                      readOnly={true}
                      disabled={true}
                    />
                  </div>
                </div>
              </CardBody>
            </Card>
            <h5 className="mt-3">Informazioni per la spedizione</h5>
            <Card>
              <CardBody>
                <div className="row">
                  <div className="col">
                    <label className="form-label">Valore merce:</label>
                    <InputBox
                      name="goodsValue"
                      value={context.order?.goodsValue || ""}
                      placeholder={"100"}
                      type="positive-number"
                      onChange={handleInputChange}
                      error={context.orderError?.response?.data?.goodsValue}
                    />
                  </div>
                  <div className="col">
                    <label className="form-label">Valore assicurazione:</label>
                    <InputBox
                      name="insuranceValue"
                      value={context.order?.insuranceValue || ""}
                      placeholder={"100"}
                      type="positive-number"
                      onChange={handleInputChange}
                      error={context.orderError?.response?.data?.insuranceValue}
                    />
                  </div>
                  <div className="col">
                    <label className="form-label">Valore contrassegno:</label>
                    <InputBox
                      name="cashOnDeliveryValue"
                      value={context.order?.cashOnDeliveryValue || ""}
                      placeholder={"100"}
                      type="positive-number"
                      onChange={handleInputChange}
                      error={
                        context.orderError?.response?.data?.cashOnDeliveryValue
                      }
                    />
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col">
                    <label className="form-label">Costo spedizione:</label>
                    <InputBox
                      name="shippingCost"
                      value={context.order?.shippingCost || ""}
                      placeholder={"100"}
                      type="positive-number"
                      onChange={handleInputChange}
                      error={context.orderError?.response?.data?.shippingCost}
                    />
                  </div>
                  <div className="col">
                    <label className="form-label">Valuta</label>
                    <Select
                      name="currency"
                      onChange={handleSelectChange}
                      defaultMenuIsOpen={false}
                      className="select"
                      options={orderBykey(currenciesList, "name")}
                      value={context.order?.currency}
                      getOptionValue={(option) => option["code"]}
                      getOptionLabel={(option) => option["name"]}
                    />
                  </div>
                  {context.order?.cashOnDeliveryValue > 0 && (
                    <div className="col">
                      <label className="form-label">
                        Metodo incasso contras.
                      </label>
                      <AsyncSelect
                        name="cashOnDeliveryCollectionMethodCode"
                        optionValue="code"
                        optionLabel="name"
                        value={
                          context.order?.cashOnDeliveryCollectionMethodCode
                        }
                        onSearch={handleAsyncSelectChange}
                        loadOptions={(inputValue, pagination) => {
                          return getAllCashOnDeliveryCollectionMethods(
                            inputValue,
                            pagination,
                            {
                              orderBy: "asc",
                              selector: "name",
                            }
                          );
                        }}
                        isClearable={false}
                      />
                    </div>
                  )}
                </div>
                <div className="row mt-3">
                  <div className="col-4">
                    <label className="form-label">Metodo di pagamento:</label>
                    <InputBox
                      name="paymentMethod"
                      value={context.order?.paymentMethod || ""}
                      type="text"
                      onChange={handleInputChange}
                      error={context.orderError?.response?.data?.paymentMethod}
                      max={50}
                    />
                  </div>
                </div>
              </CardBody>
            </Card>
          </div>
          <div className="col">
            <h5>Destinatario</h5>
            <Card>
              <CardBody>
                <div className="row">
                  <div className="col-9">
                    <AddressBookQuickView
                      defaultContact={context.order?.delivery}
                      callback={(contact) => {
                        context.createEditDelivery(contact);
                      }}
                    />
                    {context.order?.delivery?.id && (
                      <label className="form-check mb-2">
                        <input
                          name="priv"
                          type="checkbox"
                          className="form-check-input form-check-input-success"
                          value={context.newBillingContactEnabled || false}
                          checked={context.newBillingContactEnabled || false}
                          onChange={handleCheckboxChange}
                        />
                        <span className="form-check-label">
                          L'indirizzo di fatturazione è diverso da quello
                          destinatario
                        </span>
                      </label>
                    )}
                  </div>
                  <div className="col">
                    <button
                      className="btn btn-outline-success btn-sm btn btn-light mt-4 me-2 p-2"
                      disabled={!context.order?.delivery?.id}
                      onClick={() => {
                        context.setModalSettings({
                          ...context.modalSettings,
                          title: "Modifica indirizzo",
                          icon: "ph-trash",
                          open: !context.modalSettings.open,
                          action: "editDelivery",
                        });
                      }}
                      title="Modifica indirizzo"
                    >
                      <FontAwesomeIcon icon={faEdit} size="lg" />
                    </button>
                    <button
                      type="button"
                      className="btn btn-outline-success btn-sm btn btn-light mt-4 p-2"
                      onClick={() => {
                        context.setModalSettings({
                          ...context.modalSettings,
                          title: "Nuovo indirizzo",
                          icon: "ph-trash",
                          open: !context.modalSettings.open,
                          action: "createDelivery",
                        });
                      }}
                      title="Aggiungi nuovo indirizzo"
                    >
                      <FontAwesomeIcon icon={faPlus} size="lg" />
                    </button>
                  </div>
                </div>
                {context?.newBillingContactEnabled && (
                  <div className="row mt-3">
                    <h5>Indirizzo di fatturazione</h5>
                    <div className="col-9">
                      <AddressBookQuickView
                        defaultContact={context.order?.billing}
                        callback={(contact) => {
                          context.createEditBilling(contact);
                        }}
                      />
                    </div>
                    <div className="col">
                      <button
                        className="btn btn-outline-success btn-sm btn btn-light mt-4 me-2 p-2"
                        disabled={!context.order?.billing?.id}
                        onClick={() => {
                          context.setModalSettings({
                            ...context.modalSettings,
                            title: "Modifica indirizzo di fatturazione",
                            icon: "ph-trash",
                            open: !context.modalSettings.open,
                            action: "editBilling",
                          });
                        }}
                        title="Modifica indirizzo di fatturazione"
                      >
                        <FontAwesomeIcon icon={faEdit} size="lg" />
                      </button>
                      <button
                        type="button"
                        className="btn btn-outline-success btn-sm btn btn-light mt-4 p-2"
                        onClick={() => {
                          context.setModalSettings({
                            ...context.modalSettings,
                            title: "Nuovo indirizzo di fatturazione",
                            icon: "ph-trash",
                            open: !context.modalSettings.open,
                            action: "createBilling",
                          });
                        }}
                        title="Aggiungi nuovo indirizzo di fatturazione"
                      >
                        <FontAwesomeIcon icon={faPlus} size="lg" />
                      </button>
                    </div>
                  </div>
                )}
              </CardBody>
            </Card>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <h5>Prodotti</h5>
            <Card>
              <CardBody>
                <div className="row">
                  <div className="col">
                    {context.order.id && (
                      <IndexOrderDetailsProvider parentId={context.order?.id}>
                        <OrderDetails
                          parentId={context.order?.id}
                          getData={(data) => {
                            const newOrder = { ...context.order };
                            if (data.content) {
                              newOrder.totalPrice = data?.content?.reduce(
                                (total, item) => total + (item.totalPrice || 0),
                                0
                              );
                              newOrder.totalWeight = data.content.reduce(
                                (total, item) =>
                                  total + (item.totalWeight || 0),
                                0
                              );
                            } else {
                              newOrder.totalPrice = "";
                              newOrder.totalWeight = "";
                            }
                            context.editOrder(newOrder);
                          }}
                        />
                      </IndexOrderDetailsProvider>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col"></div>
                  <div className="col"></div>
                  <div className="col"></div>
                  <div className="col"></div>
                  <div className="col">
                    <label className="form-label">Peso totale:</label>
                    <InputBox
                      name="totalWeight"
                      value={context.order?.totalWeight || ""}
                      placeholder={"100"}
                      type="positive-number"
                      onChange={handleInputChange}
                      precision={3}
                      error={context.orderError?.response?.data?.totalWeight}
                    />
                  </div>
                  <div className="col"></div>
                  <div className="col"></div>
                  <div className="col">
                    <label className="form-label">Prezzo totale:</label>
                    <InputBox
                      name="totalPrice"
                      value={context.order?.totalPrice || ""}
                      placeholder={"100"}
                      type="positive-number"
                      onChange={handleInputChange}
                      error={context.orderError?.response?.data?.totalPrice}
                    />
                  </div>
                  <div className="col-2">
                    <label className="form-label">
                      Prezzo totale (+ IVA 22%):
                    </label>{" "}
                    <label className="form-label d-block">
                      {((context.order?.totalPrice || 0) * 1.22).toFixed(2)}
                    </label>
                  </div>
                </div>
              </CardBody>
            </Card>
          </div>
        </div>
        <div className="row">
          <div className="col text-end">
            {context.order?.id && (
              <React.Fragment>
                <button
                  className="btn btn-outline-danger btn-sm btn btn-light ms-2"
                  onClick={() => {
                    context.setModalSettings({
                      ...context.modalSettings,
                      title: "Elimina ordine",
                      icon: "ph-check",
                      open: !context.modalSettings.open,
                      action: "delete",
                      orders: [context.order],
                    });
                  }}
                >
                  <i className="ph-trash me-2"></i>
                  Elimina
                </button>
                <button
                  className="btn btn-outline-success btn-sm btn btn-light ms-2"
                  onClick={() => {
                    context.setModalSettings({
                      ...context.modalSettings,
                      title: "Conferma ordine",
                      icon: "ph-check",
                      open: !context.modalSettings.open,
                      action: "confirm",
                      orders: [context.order?.id],
                    });
                  }}
                >
                  <i className="ph-check me-2"></i>
                  Conferma
                </button>
              </React.Fragment>
            )}
          </div>
        </div>
      </div>

      <Modal
        isOpen={context.modalSettings.open}
        className="modal-content"
        size={context.modalSettings.size || "md"}
      >
        <ModalHeader
          toggle={() =>
            context.setModalSettings({
              ...modalSettingsDefault,
              open: !context.modalSettings.open,
            })
          }
        >
          <span className="modal-title">
            <i className={context.modalSettings.icon + " me-2"}></i>
            {context.modalSettings.title}
          </span>
        </ModalHeader>
        <ModalBody>
          {context.modalSettings.action === "createDelivery" && (
            <CreateEditContactProvider
              callback={(contact) => {
                context.createEditDelivery(contact);
              }}
            >
              <CreateEditContact />
            </CreateEditContactProvider>
          )}
          {context.modalSettings.action === "editDelivery" && (
            <CreateEditContactProvider
              defaultContact={context.order?.delivery}
              callback={(contact) => {
                context.createEditDelivery(contact);
              }}
            >
              <CreateEditContact />
            </CreateEditContactProvider>
          )}
          {context.modalSettings.action === "createBilling" && (
            <CreateEditContactProvider
              callback={(contact) => {
                context.createEditBilling(contact);
              }}
            >
              <CreateEditContact />
            </CreateEditContactProvider>
          )}
          {context.modalSettings.action === "editBilling" && (
            <CreateEditContactProvider
              defaultContact={context.order?.billing}
              callback={(contact) => {
                context.createEditBilling(contact);
              }}
            >
              <CreateEditContact />
            </CreateEditContactProvider>
          )}
          {context.modalSettings.action === "confirm" && (
            <ConfirmOrdersProvider
              data={context.modalSettings.orders}
              callback={() => {
                context.setModalSettings({
                  modalSettingsDefault,
                  open: !context.modalSettings.open,
                });
                navigate("/orders");
              }}
            >
              <Confirm />
            </ConfirmOrdersProvider>
          )}
          {context.modalSettings.action === "delete" && (
            <DeleteOrdersProvider
              data={context.modalSettings.orders}
              callback={() => {
                context.setModalSettings({
                  modalSettingsDefault,
                  open: !context.modalSettings.open,
                });
                navigate("/orders");
              }}
            >
              <Delete />
            </DeleteOrdersProvider>
          )}
          {/* {context.modalSettings.action === "delete" && (
            <DeleteOrderProvider
              data={context.modalSettings.orders}
              unmountCallback={() => {
                context.setModalSettings({
                  modalSettingsDefault,
                  open: !context.modalSettings.open,
                });
                navigate("/orders");
              }}
            >
              <Delete />
            </DeleteOrderProvider>
          )}
          {context.modalSettings.action === "confirm" && (
            <ConfirmOrderProvider
              data={context.modalSettings.orders}
              unmountCallback={() => {
                context.setModalSettings({
                  modalSettingsDefault,
                  open: !context.modalSettings.open,
                });
                navigate("/orders");
              }}
            >
              <Confirm
                cashOnDeliveryCollectionMethodCode={
                  context.order?.cashOnDeliveryCollectionMethodCode?.name
                }
                cashOnDeliveryValue={context.order?.cashOnDeliveryValue}
                currency={context.order?.currency?.name}
              />
            </ConfirmOrderProvider>
          )} */}
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};
