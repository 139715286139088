import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLoader } from "@awesome.me/kit-c1b85ff10f/icons/classic/light";

export const ButtonUtility = ({
  children,
  className = "",
  onClick,
  disabled = false,
  loading = false,
  title = "",
}) => {
  return (
    <button
      title={title}
      className={`button-1 button-1-light ${className}`}
      onClick={() => {
        if (onClick) {
          onClick();
        }
      }}
      disabled={disabled || loading}
    >
      {loading ? <FontAwesomeIcon icon={faLoader} spin /> : children}
    </button>
  );
};
