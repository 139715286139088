import { callErrorToast, createFiltersObject } from ".";

import { CarriersService as CarriersRegistryService } from "../services/registry/carriers";
import { CarriersService as CarriersShipmentService } from "../services/shipment/carriers";
import { StatusCodesService } from "../services/shipment/statusCodes";
import { CompaniesService } from "../services/registry/companies";
import { CarrierConfigurationsService } from "../services/contract/carrierConfigurations";
import { StoresService } from "../services/registry/stores";
import { LogisticsService } from "../services/registry/logistics";
import { LogisticIntegrationsService } from "../services/logistic/logisticIntegrations";
import { StoreIntegrationsService } from "../services/logistic/storeIntegrations";
import { WarehousesService } from "../services/logistic/warehouses";
import { WarehouseArticlesService } from "../services/logistic/warehouseArticles";
import { ArticleVariantsService } from "../services/logistic/articleVariants";
import { ArticlesService } from "../services/logistic/articles";
import { CashOnDeliveryCollectionMethodsService } from "../services/registry/cashOnDeliveryCollectionMethods";
import { AddressBookService as AddressBookRegistryService } from "../services/registry/addressBook";
import { CarrierServicesService } from "../services/registry/carrierServices";

const statusCodesService = new StatusCodesService();
const carrierServicesService = new CarrierServicesService();
const companiesService = new CompaniesService();
const carriersRegistryService = new CarriersRegistryService();
const carriersShipmentService = new CarriersShipmentService();
const storesService = new StoresService();
const logisticsService = new LogisticsService();
const carrierConfigurationsService = new CarrierConfigurationsService();
const logisticIntegrationsService = new LogisticIntegrationsService();
const storeIntegrationsService = new StoreIntegrationsService();
const warehousesService = new WarehousesService();
const warehouseArticlesService = new WarehouseArticlesService();
const articleVariantsService = new ArticleVariantsService();
const articlesService = new ArticlesService();
const cashOnDeliveryCollectionMethodsService =
  new CashOnDeliveryCollectionMethodsService();
const addressBookRegistryService = new AddressBookRegistryService();

export const getAllStores = (inputValue, pagination, sorting, search = []) => {
  return storesService
    .all(createFiltersObject(inputValue, pagination, sorting, search))
    .then((response) => {
      return response;
    })
    .catch((err) => {
      callErrorToast(err);
    });
};

export const getAllCarriers = (
  inputValue,
  pagination,
  sorting,
  search = []
) => {
  return carriersRegistryService
    .all(createFiltersObject(inputValue, pagination, sorting, search))
    .then((response) => {
      return response;
    })
    .catch((err) => {
      callErrorToast(err);
    });
};

export const getAllShipmentsCarriers = (
  inputValue,
  pagination,
  sorting,
  search = []
) => {
  return carriersShipmentService
    .all(createFiltersObject(inputValue, pagination, sorting, search))
    .then((response) => {
      return response;
    })
    .catch((err) => {
      callErrorToast(err);
    });
};

export const getAllLogistics = (
  inputValue,
  pagination,
  sorting,
  search = []
) => {
  return logisticsService
    .all(createFiltersObject(inputValue, pagination, sorting, search))
    .then((response) => {
      return response;
    })
    .catch((err) => {
      callErrorToast(err);
    });
};

export const getAllCarrierConfigurations = (
  inputValue,
  pagination,
  sorting,
  search = []
) => {
  return carrierConfigurationsService
    .all(createFiltersObject(inputValue, pagination, sorting, search))
    .then((response) => {
      return response;
    })
    .catch((err) => {
      callErrorToast(err);
    });
};

export const getAllCarriersFromCarrierConfigurations = (
  inputValue,
  pagination,
  sorting,
  search = []
) => {
  return carrierConfigurationsService
    .all(createFiltersObject(inputValue, pagination, sorting, search))
    .then((response) => {
      response.data.content = response.data.content.filter(
        (item, index, self) =>
          index === self.findIndex((t) => t.carrierCode === item.carrierCode)
      );
      return response;
    })
    .catch((err) => {
      callErrorToast(err);
    });
};

export const getAllArticles = (
  inputValue,
  pagination,
  sorting,
  search = []
) => {
  return articlesService
    .all(createFiltersObject(inputValue, pagination, sorting, search))
    .then((response) => {
      return response;
    })
    .catch((err) => {
      callErrorToast(err);
    });
};

export const getAllArticleVariants = (
  inputValue,
  pagination,
  sorting,
  search = []
) => {
  return articleVariantsService
    .all(createFiltersObject(inputValue, pagination, sorting, search))
    .then((response) => {
      return response;
    })
    .catch((err) => {
      callErrorToast(err);
    });
};

export const getAllLogisticIntegrations = (
  inputValue,
  pagination,
  sorting,
  search = []
) => {
  return logisticIntegrationsService
    .all(createFiltersObject(inputValue, pagination, sorting, search))
    .then((response) => {
      return response;
    })
    .catch((err) => {
      callErrorToast(err);
    });
};

export const getAllStatusCodes = (
  inputValue,
  pagination,
  sorting,
  search = []
) => {
  return statusCodesService
    .all(createFiltersObject(inputValue, pagination, sorting, search))
    .then((response) => {
      return response;
    })
    .catch((err) => {
      callErrorToast(err);
    });
};

export const getAllCompanies = (
  inputValue,
  pagination,
  sorting,
  search = []
) => {
  return companiesService
    .all(createFiltersObject(inputValue, pagination, sorting, search))
    .then((response) => {
      return response;
    })
    .catch((err) => {
      callErrorToast(err);
    });
};

export const getAllStoreIntegrations = (
  inputValue,
  pagination,
  sorting,
  search = []
) => {
  return storeIntegrationsService
    .all(createFiltersObject(inputValue, pagination, sorting, search))
    .then((response) => {
      return response;
    })
    .catch((err) => {
      callErrorToast(err);
    });
};

export const getAllWarehouses = (
  inputValue,
  pagination,
  sorting,
  search = []
) => {
  return warehousesService
    .all(createFiltersObject(inputValue, pagination, sorting, search))
    .then((response) => {
      return response;
    })
    .catch((err) => {
      callErrorToast(err);
    });
};

export const getAllWarehouseArticles = (
  inputValue,
  pagination,
  sorting,
  search = [],
  parentId
) => {
  return warehouseArticlesService
    .all(parentId, createFiltersObject(inputValue, pagination, sorting, search))
    .then((response) => {
      return response;
    })
    .catch((err) => {
      callErrorToast(err);
    });
};

export const getAllCashOnDeliveryCollectionMethods = (
  inputValue,
  pagination,
  sorting,
  search = []
) => {
  return cashOnDeliveryCollectionMethodsService
    .all(createFiltersObject(inputValue, pagination, sorting, search))
    .then((response) => {
      return response;
    })
    .catch((err) => {
      callErrorToast(err);
    });
};

export const getAllCarrierCashOnDeliveryCollectionMethods = (code) => {
  return carriersRegistryService
    .getCashOnDeliveryCollectionMethods(code)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      callErrorToast(err);
    });
};

export const getAllRegistryContacts = (
  inputValue,
  pagination,
  sorting,
  search = []
) => {
  return addressBookRegistryService
    .all(createFiltersObject(inputValue, pagination, sorting, search))
    .then((response) => {
      return response;
    })
    .catch((err) => {
      callErrorToast(err);
    });
};

export const getAllCarrierServiceAttachmentTypes = (code) => {
  return carrierServicesService
    .getAttachmentTypes(code)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      callErrorToast(err);
    });
};
