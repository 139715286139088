import { useEffect, useState, useCallback } from "react";
import { useOidcFetch } from "@axa-fr/react-oidc";

import * as constants from "../constants";

export default function useFetch(endpoint, opt = {}, wait = false) {
  const { fetch } = useOidcFetch();
  const [data, setData] = useState({});
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchAsync = async (url, options) => {
    try {
      const res = await fetch(url, options);
      if (!res.ok) {
        // Verifica se la risposta è ok (200-299)
        const error = new Error("Network response was not ok");
        error.response = res; // Attach the response to the error
        throw error; // Throw the custom error
      }
      return await res.json(); // Ritorna i dati in caso di successo
    } catch (err) {
      setError(err); // Imposta l'errore nello stato
      return {}; // Ritorna null in caso di errore
    }
  };

  const invokeFetch = (url = endpoint, option = opt, waitFor = wait) => {
    if (!waitFor) {
      setLoading(true);
      return fetchAsync(constants.API_BASE_URL + url, option)
        .then((res) => {
          setData(res);
          return res; // Ritorna i dati per l'utilizzo da parte del chiamante
        })
        .catch((err) => {
          setError(err);
          throw err; // Rilancia l'errore per consentire la gestione con la Promise
        })
        .finally(() => {
          setLoading(false);
        });
    }
    return Promise.resolve(); // Ritorna una promise risolta se `waitFor` è true
  };

  const refreshData = useCallback(
    (wait = false) => {
      return new Promise((resolve, reject) => {
        invokeFetch(endpoint, opt, wait).then(resolve).catch(reject);
      });
    },
    [endpoint, opt, wait]
  );

  useEffect(() => {
    if (endpoint) invokeFetch(endpoint, opt);
  }, [endpoint, wait]);

  return { data, error, loading, refreshData };
}
