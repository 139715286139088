import moment from "moment";
import {
  getAllCompanies,
  getAllStores,
  getAllWarehouses,
} from "../../utilities/asyncSelectCallAPI";
import {
  getCompanyOptionStyle,
  getOrderStatusOptionStyle,
  getStoreOptionStyle,
  getWarehouseOptionStyle,
} from "../../Components/common/AsyncSelect/utilities";
import {
  getOrderStatusBadge,
  getOrderTypeBadge,
  getParcelTypeBadge,
  orderStatus,
  orderTypes,
} from "../../utilities";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInfoCircle,
  faTruckFast,
} from "@awesome.me/kit-c1b85ff10f/icons/classic/light";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import { UncontrolledTooltip } from "reactstrap";
import CashOnDeliveryIcon from "../../assets/images/cashOnDeliveryIcon.png";
import InsuranceIcon from "../../assets/images/insuranceIcon.png";

export const allSelectedOrdersCanBeDeleted = (
  orderSelectedIds = [],
  orders = []
) => {
  const idSelected = orderSelectedIds?.filter((x) => x.value).map((x) => x.id);
  const ordersSelected = orders.filter((x) => idSelected.includes(x.id));
  return canAllOrdersBeDeleted(ordersSelected);
};

export const canOrderBeDeleted = (order) => {
  if (order.lastStatus === "DRAFT") {
    return true;
  }
  return false;
};

export const canAllOrdersBeDeleted = (orders) => {
  if (!orders || orders.length === 0) return false;
  const selectedOrderCanBeDeleted = orders.map((x) => canOrderBeDeleted(x));
  return selectedOrderCanBeDeleted.every((x) => x);
};

// //#region Index columns
export const columns = [
  {
    position: 1,
    Header: "",
    accessor: "shipments",
    sortable: false,
    Cell: ({ cell }) => {
      const shipment = cell.row.original.shipments
        ? cell.row.original.shipments[0]
        : null;
      return shipment?.id ? (
        <Link
          target="_blank"
          to={"/shipments/details/" + cell.row.original.shipments[0]?.id}
        >
          <FontAwesomeIcon icon={faTruckFast} />
        </Link>
      ) : (
        ""
      );
    },
  },
  {
    position: 1,
    Header: "Cliente",
    accessor: "owner.name",
    cellClass: "text-nowrap",
    filter: {
      active: true,
      name: "ownerId",
      type: "async-select",
      loadOptions: (searchValue, pagination) => {
        return getAllCompanies(
          searchValue,
          pagination,
          {
            orderBy: "asc",
            selector: "name",
          },
          [{ selector: "type", value: "CUSTOMER", preciseSearch: true }]
        );
      },
      multi: true,
      optionStyle: getCompanyOptionStyle,
      col: 4,
    },
  },
  {
    position: 2,
    Header: "Data",
    accessor: "creationDate",
    sortable: true,
    filter: {
      active: true,
      name: "creationDate",
      type: "datepicker",
      col: 2,
    },
    Cell: ({ cell }) => {
      return moment(cell.row.original.creationDate).format(
        "DD/MM/YYYY HH:mm:ss"
      );
    },
  },
  {
    position: 3,
    Header: "Store",
    accessor: "storeIntegration.store.name",
    filter: {
      active: true,
      name: "storeIntegration.store.name",
      type: "async-select",
      loadOptions: (searchValue, pagination) => {
        return getAllStores(searchValue, pagination, {
          orderBy: "asc",
          selector: "name",
        });
      },
      multi: true,
      optionStyle: getStoreOptionStyle,
      col: 3,
    },
    Cell: ({ cell }) => {
      const row = cell.row.original;
      if (row.storeIntegration?.store?.logoUrl) {
        return <img width={50} src={row.storeIntegration?.store?.logoUrl} />;
      }
      return "-";
    },
  },
  {
    position: 4,
    Header: "Rif.Interno",
    accessor: "internalReference",
    cellClass: "text-nowrap",
  },
  {
    position: 5,
    Header: "Rif.Esterno",
    accessor: "externalReference",
    cellClass: "text-nowrap",
  },
  {
    position: 6,
    Header: "Rif.Logistica",
    accessor: "logisticReference",
    cellClass: "text-nowrap",
  },
  {
    position: 7,
    Header: "Rif.Store",
    accessor: "storeReference",
    cellClass: "text-nowrap",
  },
  {
    position: 8,
    Header: "Stato",
    accessor: "lastStatus",
    style: { minWidth: "12rem" },
    filter: {
      active: true,
      name: "lastStatus",
      type: "select",
      options: orderStatus,
      multi: true,
      optionStyle: getOrderStatusOptionStyle,
      col: 3,
    },
    Cell: ({ row }) => {
      const shipment = row.original.shipments
        ? row.original.shipments[0]
        : null;
      return shipment?.lastStatusCode ? (
        <div className="d-flex align-items-center">
          <span
            style={{ backgroundColor: shipment?.lastStatusColor }}
            className="badge"
            title={shipment?.lastStatusName}
          >
            {shipment?.lastStatusName}
          </span>
        </div>
      ) : (
        getOrderStatusBadge(row.original.lastStatus)
      );
    },
  },
  {
    position: 9,
    Header: "Data di consegna",
    accessor: "deliveryDate",
    Cell: ({ row }) => {
      const shipment = row.original.shipments
        ? row.original.shipments[0]
        : null;
      return (
        shipment?.deliveryDate &&
        moment(shipment.deliveryDate).format("DD/MM/YYYY HH:mm:ss")
      );
    },
  },
  {
    position: 10,
    Header: "Destinatario",
    accessor: "delivery",
    cellClass: "text-nowrap",
    filter: {
      active: true,
      name: "delivery.name",
      type: "search",
      preciseSearch: true,
    },
    Cell: ({ cell }) => {
      return cell.row.original.delivery
        ? cell.row.original.delivery?.name ||
            +" - " + cell.row.original.delivery?.location?.label
        : "";
    },
  },
  {
    position: 11,
    Header: "Tipologia",
    accessor: "type",
    filter: {
      active: true,
      name: "type",
      type: "select",
      options: orderTypes,
      multi: true,
      optionStyle: (option) => {
        return getOrderTypeBadge(option?.value);
      },
      col: 2,
    },
    Cell: ({ row }) => {
      const { original } = row;
      return getOrderTypeBadge(original.type);
    },
  },
  {
    position: 12,
    Header: "Utente di creazione",
    accessor: "creationUser",
    Cell: ({ row }) => {
      const { original } = row;
      return original.creationUser || "-";
    },
  },
  {
    position: 13,
    Header: "COD",
    accessor: "cashOnDeliveryValue",
    filter: {
      active: false,
      name: "cashOnDeliveryValue",
      type: "select",
      options: [
        { value: true, label: "SI" },
        { value: false, label: "NO" },
      ],
    },
    Cell: ({ row }) => {
      const cashOnDeliveryValue = row.original.cashOnDeliveryValue;
      return cashOnDeliveryValue ? (
        <img
          width={30}
          src={CashOnDeliveryIcon}
          title={`Contrassegno: ${cashOnDeliveryValue.toFixed(2)} ${
            row.original.currency
          } ${row.original.cashOnDeliveryCollectionMethodName || ""}`}
        />
      ) : (
        "-"
      );
    },
    col: 3,
  },
  {
    position: 14,
    Header: "Ins.",
    accessor: "insuranceValue",
    filter: {
      active: false,
      name: "cashOnDeliveryValue",
      type: "select",
      options: [
        { value: true, label: "SI" },
        { value: false, label: "NO" },
      ],
    },
    Cell: ({ row }) => {
      const insuranceValue = row.original.insuranceValue;
      return insuranceValue ? (
        <img
          width={30}
          src={InsuranceIcon}
          title={`Assicurazione: ${insuranceValue.toFixed(2)} ${
            row.original.currency
          }`}
        />
      ) : (
        "-"
      );
    },
    col: 3,
  },
  {
    position: 15,
    Header: "Barcodes",
    accessor: "barcodes",
    Cell: ({ row }) => {
      const { original } = row;
      return original.barcodes?.join("; ") || "-";
    },
  },
  {
    position: 16,
    Header: "N° Prodotti",
    accessor: "orderDetails",
    Cell: ({ row }) => {
      const { original } = row;
      return original.orderDetails || 0;
    },
  },
  {
    position: 17,
    Header: "Magazzino",
    accessor: "warehouse.id",
    filter: {
      active: true,
      name: "warehouse.id",
      type: "async-select",
      loadOptions: (searchValue, pagination) => {
        return getAllWarehouses(searchValue, pagination, {
          orderBy: "asc",
          selector: "name",
        });
      },
      multi: false,
      optionStyle: getWarehouseOptionStyle,
      col: 3,
    },
    Cell: ({ row }) => {
      const { original } = row;
      return original.warehouse.name || 0;
    },
  },
];
// //#endregion

export const normalizer = (order, accessTokenPayload) => {
  order.sourceIntegrationId = order.sourceIntegration?.id;
  order.ownerId = order.ownerId || accessTokenPayload.owner;
  order.deliveryId = order.delivery?.id;
  order.billingId = order.billing?.id;
  order.warehouseId = order.warehouse?.id;
  order.cashOnDeliveryCollectionMethodCode = order.cashOnDeliveryValue
    ? order.cashOnDeliveryCollectionMethodCode?.code ||
      order.cashOnDeliveryCollectionMethodCode ||
      "CASH"
    : null;
  order.currency = order.currency?.code || order.currency;
  if (order.sourceIntegrationId) {
    order.sourceType = "STORE";
  }
  return order;
};
