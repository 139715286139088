import React, { useEffect } from "react";
import { faLoader } from "@awesome.me/kit-c1b85ff10f/icons/classic/light";
import { Doughnut, Pie } from "react-chartjs-2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useShipmentsContext } from "./providers/Shipments";
import { useAppContext } from "../../AppProvider";
import { dictionary } from "../../utilities/dictionary";
import { doughnutCenterTextPlugin, doughnutOptions } from "./utilities";

const ShipmentsByStatus = ({ subtitle = "" }) => {
  const context = useShipmentsContext();
  const appContext = useAppContext();
  const language = appContext?.language;

  useEffect(() => {
    context.getStatistics();
  }, []);

  const statuses = context?.kpi?.statuses || [];
  const data = {
    labels: statuses.map((s) => s.type.name),
    datasets: [
      {
        data: statuses.map((s) => s.total),
        backgroundColor: statuses.map((s) => s.type.color),
      },
    ],
  };
  const totalShipments = statuses?.reduce((acc, s) => acc + s.total, 0) || "ND";

  return (
    context && (
      <div className="d-flex flex-column align-items-center justify-content-center">
        {context.kpiLoader ? (
          <FontAwesomeIcon icon={faLoader} spin size="4x" />
        ) : (
          <>
            <label className="label-4 mb-2">
              {dictionary["shipments_by_status"][language]}
            </label>
            {subtitle && <label className="label-2">{subtitle}</label>}
            {data.datasets[0].data.length > 0 ? (
              <>
                <Doughnut
                  className="mt-2"
                  data={data}
                  options={doughnutOptions}
                  plugins={[doughnutCenterTextPlugin(totalShipments)]}
                />
              </>
            ) : (
              <label className="label-3 mt-5">
                {dictionary["no_data_show"][language]}
              </label>
            )}
          </>
        )}
      </div>
    )
  );
};

export { ShipmentsByStatus };
