import React, { createContext, useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { OrdersService } from "../../../services/logistic/orders";
import { CashOnDeliveryCollectionMethodsService } from "../../../services/registry/cashOnDeliveryCollectionMethods";

export const DetailsOrderContext = createContext();

const ordersService = new OrdersService();
const cashOnDeliveryCollectionMethodsService =
  new CashOnDeliveryCollectionMethodsService();

export const DetailsOrderProvider = ({ children, data }) => {
  const { id } = useParams();

  const [order, setOrder] = useState(data);
  const [orderError, setOrderError] = useState(null);
  const [orderLoader, setOrderLoader] = useState(true);

  const [cashOnDeliveryMethod, setCashOnDeliveryMethod] = useState(null);
  const [cashOnDeliveryMethodError, setCashOnDeliveryMethodError] =
    useState(null);
  const [cashOnDeliveryMethodLoader, setCashOnDeliveryMethodLoader] =
    useState(false);

  const getOrder = () => {
    ordersService
      .get(id)
      .then((res) => {
        const order = {
          ...res.data,
        };

        if (order.cashOnDeliveryCollectionMethodCode) {
          getCashOnDeliveryMethod(order.cashOnDeliveryCollectionMethodCode);
        }

        setOrder(order);
        setOrderLoader(false);
      })
      .catch((err) => setOrderError(err));
  };

  const getCashOnDeliveryMethod = (code) => {
    setCashOnDeliveryMethodLoader(true);
    cashOnDeliveryCollectionMethodsService
      .get(code)
      .then((res) => {
        setCashOnDeliveryMethod(res.data);
        setCashOnDeliveryMethodLoader(false);
      })
      .catch((err) => setCashOnDeliveryMethodError(err));
  };

  return (
    <DetailsOrderContext.Provider
      value={{
        //#region Order
        order,
        orderError,
        orderLoader,
        getOrder,
        //#endregion

        //#region cashOnDeliveryMethod
        cashOnDeliveryMethod,
        cashOnDeliveryMethodError,
        //#endregion
      }}
    >
      {children}
    </DetailsOrderContext.Provider>
  );
};
