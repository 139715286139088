import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faLoader,
  faTrash,
} from "@awesome.me/kit-c1b85ff10f/icons/classic/light";
import { dictionary } from "../../../utilities/dictionary";
import { useAppContext } from "../../../AppProvider";

export const ButtonConfirm = ({
  children,
  className = "",
  onClick,
  disabled = false,
  loading = false,
  icon = true,
}) => {
  const appContext = useAppContext();
  const language = appContext?.language;
  return (
    <button
      className={`button-1 button-1-dark ${className}`}
      onClick={() => {
        if (onClick) {
          onClick();
        }
      }}
      disabled={disabled || loading}
    >
      {loading ? (
        <FontAwesomeIcon icon={faLoader} spin />
      ) : (
        <>
          {icon && <FontAwesomeIcon icon={faCheck} />}
          {children || dictionary["confirm"][language]}
        </>
      )}
    </button>
  );
};
