export const Checkbox = ({
  label,
  checked,
  onChange,
  name,
  disabled,
  className = "",
}) => {
  return (
    <label className="checkbox">
      <input
        className={className}
        name={name}
        type="checkbox"
        checked={checked}
        onChange={onChange}
        disabled={disabled}
      />
      <span className={`checkmark ${className}`}></span>
      {label && <span className="span-1">{label}</span>}
    </label>
  );
};
