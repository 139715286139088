import {
  faArrowDownShortWide,
  faArrowUpShortWide,
  faCalendar,
  faCheck,
  faClipboardList,
  faDownToBracket,
  faFileCsv,
  faPlus,
  faTrash,
} from "@awesome.me/kit-c1b85ff10f/icons/classic/light";
import { useAppContext } from "../../../AppProvider";
import { dictionary } from "../../../utilities/dictionary";
import { useIndexShipmentsContext } from "../providers/Index";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  getAllCarriers,
  getAllCarriersFromCarrierConfigurations,
  getAllCompanies,
  getAllShipmentsCarriers,
  getAllStatusCodes,
} from "../../../utilities/asyncSelectCallAPI";
import {
  getCarrierFromCarrierIntegrationOptionStyle,
  getCarrierOptionStyle,
  getCompanyOptionStyle,
  getParcelTypeOptionStyle,
  getShipmentStatusOptionStyle,
  getShipmentTypeOptionStyle,
} from "../../../Components/common/AsyncSelect/utilities";
import { useTypes } from "../../../utilities/types";
import moment from "moment";

const statusValidForDownload = [
  "BOOKED",
  "CONFIRMED",
  "AVAILABLE",
  "IN_TRANSIT",
  "OUT_OF_DLV",
  "EXCEPTION",
  "FAILED_DLV",
  "DELIVERED",
  "INFO_RCVD",
];
const statusValidForDelete = ["DRAFT", "BOOKED"];
const statusValidForConfirm = ["BOOKED"];
const statusValidForCollection = ["BOOKED", "CONFIRMED"];
const statusValidForDownloadManifest = ["CONFIRMED", "INFO_RCVD"];

export const isDisabledDownload = (selectedRows) =>
  !selectedRows.every((item) =>
    statusValidForDownload.includes(item.statusCode)
  );

export const isDisabledDownloadManifest = (selectedRows) =>
  !selectedRows.every(
    (item) =>
      statusValidForDownloadManifest.includes(item.statusCode) &&
      item.carrierCode === selectedRows[0]?.carrierCode &&
      item.senderId === selectedRows[0]?.senderId
  );

export const isDisabledDelete = (selectedRows) =>
  !selectedRows.every((item) => statusValidForDelete.includes(item.statusCode));

export const isDisabledConfirm = (selectedRows) =>
  !selectedRows.every((item) =>
    statusValidForConfirm.includes(item.statusCode)
  );

export const isDisabledCollection = (selectedRows) =>
  !selectedRows.every(
    (item) =>
      statusValidForCollection.includes(item.statusCode) &&
      item.collectionCreationEnabled &&
      !item.collectionAssigned &&
      item.carrierCode === selectedRows[0]?.carrierCode
  );

export const isDisabledReturn = (selectedRows) =>
  !selectedRows.every((item) => item.statusCode === "DELIVERED");

export const useListProps = () => {
  const navigate = useNavigate();

  const appContext = useAppContext();
  const language = appContext?.language;

  const context = useIndexShipmentsContext();

  const types = useTypes();
  return {
    path: "/shipment-service/shipments",
    sorting: [
      {
        orderBy: "desc",
        column: "creationDate",
        icon: faArrowDownShortWide,
        label: dictionary["creation_date"][language],
        isDefault: true,
      },
      {
        orderBy: "asc",
        column: "creationDate",
        icon: faArrowUpShortWide,
        label: dictionary["creation_date"][language],
        isDefault: false,
      },
    ],
    banner: {
      enabled: true,
    },
    title: dictionary["my_shipments"][language],
    omnisearch: {
      enabled: true,
      placeholder: dictionary["references"][language],
    },
    defaultDate: {
      enabled: true,
      fieldName: "creationDate",
      label: dictionary["creation_date"][language],
      value: [
        moment().subtract(1, "months").startOf("day").toDate(),
        moment().endOf("day").toDate(),
      ],
    },
    filters: {
      name: "shipments",
      preserve: false,
      list: [
        {
          fieldName: "senderId",
          label: dictionary["sender"][language],
          type: "async-select",
          loadOptions: (searchValue, pagination) => {
            return getAllCompanies(searchValue, pagination, {
              orderBy: "asc",
              selector: "name",
            });
          },
          isMulti: true,
          optionStyle: getCompanyOptionStyle,
          primary: true,
        },
        {
          fieldName: "delivery.name",
          label: dictionary["receiver"][language],
          type: "text",
          preciseSearch: false,
          placeholder: "Allabox srl",
          primary: true,
        },
        {
          fieldName: "contractService.contract.carrierCode",
          label: dictionary["carrier"][language],
          type: "async-select",
          loadOptions: (searchValue, pagination) => {
            return getAllCarriersFromCarrierConfigurations(
              searchValue,
              pagination,
              {
                orderBy: "asc",
                selector: "name",
              }
            );
          },
          searchKey: "carrierCode",
          isMulti: false,
          optionStyle: getCarrierFromCarrierIntegrationOptionStyle,
          primary: true,
        },
        {
          fieldName: "status.code",
          label: dictionary["status"][language],
          type: "async-select",
          loadOptions: (searchValue, pagination) => {
            return getAllStatusCodes(searchValue, pagination, {
              orderBy: "asc",
              selector: "name",
            });
          },
          isMulti: true,
          optionStyle: getShipmentStatusOptionStyle,
          primary: true,
        },

        {
          fieldName: "parcelType.code",
          label: dictionary["package_type"][language],
          type: "select",
          options: types.parcels,
          isMulti: true,
          optionStyle: getParcelTypeOptionStyle,
          primary: false,
          col: 2,
        },
        {
          fieldName: "creationUser",
          label: dictionary["creation_user"][language],
          type: "text",
          primary: false,
          col: 2,
          placeholder: "user@allabox.it",
        },
        {
          fieldName: "shipmentType",
          label: dictionary["shipment_type"][language],
          type: "select",
          options: types.shipments,
          isMulti: true,
          optionStyle: getShipmentTypeOptionStyle,
          primary: false,
          col: 2,
        },
        {
          fieldName: "deliveryDate",
          label: dictionary["delivery_date"][language],
          type: "datepicker",
          primary: false,
          col: 2,
        },
        {
          fieldName: "cashOnDeliveryValue",
          label: dictionary["cash_on_delivery"][language],
          type: "select",
          options: types.confirmationOptions,
          primary: false,
          col: 2,
        },
        {
          fieldName: "codCollected",
          label: dictionary["cod_collected"][language],
          type: "select",
          disablingParams: [{ fieldName: "cashOnDeliveryValue" }],
          options: types.confirmationBoolOptions,
          primary: false,
          col: 2,
        },
        {
          fieldName: "insuranceValue",
          label: dictionary["insurance"][language],
          type: "select",
          options: types.confirmationOptions,
          primary: false,
          col: 2,
        },
      ],
    },
    exports: [
      {
        title: dictionary["export"][language] + " CSV",
        icon: <FontAwesomeIcon icon={faFileCsv} />,
        onClick: context.onExportCSV,
        isDisabled: context.exportLoader,
      },
    ],
    create: {
      enabled: true,
      action: () => {
        navigate("/shipments/create");
      },
      title: dictionary["create_new_shipment"][language],
      icon: faPlus,
    },
    massiveActions: [
      {
        onClick: (selectedRows) =>
          context.setModal({
            open: true,
            title: dictionary["book_collection"][language],
            action: "collection",
            data: selectedRows.map((item) => item.id),
            carrierCode: selectedRows[0].carrierCode,
            pickupId: selectedRows[0].pickupId,
          }),
        isDisabled: isDisabledCollection,
        label: dictionary["book_collection"][language],
        icon: <FontAwesomeIcon icon={faCalendar} />,
        className: "button-1 button-1-transparent",
      },
      {
        onClick: (selectedRows) =>
          context.setModal({
            open: true,
            title: dictionary["confirm_download"][language],
            action: "print",
            data: selectedRows.map((item) => item.id),
          }),
        isDisabled: isDisabledDownload,
        label: dictionary["print_labels"][language],
        icon: <FontAwesomeIcon icon={faDownToBracket} />,
        className: "button-1 button-1-transparent",
      },
      {
        onClick: (selectedRows) => {
          localStorage.removeItem("manifest_filters");
          localStorage.setItem(
            "manifest_filters",
            JSON.stringify({
              internalReference: selectedRows.map((r) => r.internalReference),
              "contractService.contract.carrierCode":
                selectedRows[0].carrierCode,
            })
          );
          window.open("/shipments/manifest", "_blank", "noopener,noreferrer");
        },
        isDisabled: isDisabledDownloadManifest,
        label: dictionary["print_manifest"][language],
        icon: <FontAwesomeIcon icon={faClipboardList} />,
        className: "button-1 button-1-transparent",
      },
      {
        onClick: (selectedRows) =>
          context.setModal({
            open: true,
            title: dictionary["confirm_shipment"][language],
            action: "confirm",
            data: selectedRows.map((item) => item.id),
          }),

        isDisabled: isDisabledConfirm,
        label: dictionary["confirm_selected"][language],
        icon: <FontAwesomeIcon icon={faCheck} />,
        className: "button-1 button-1-dark",
      },
      {
        onClick: (selectedRows) =>
          context.setModal({
            open: true,
            title: dictionary["confirm_deletion"][language],
            action: "delete",
            data: selectedRows.map((item) => item.id),
          }),

        isDisabled: isDisabledDelete,
        label: dictionary["delete_selected"][language],
        icon: <FontAwesomeIcon icon={faTrash} />,
        className: "button-1 button-1-danger",
      },
    ],
  };
};
