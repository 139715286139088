import React, { createContext, useContext, useEffect, useState } from "react";

import { OrdersService } from "../../../services/logistic/orders";
import { callErrorToast } from "../../../utilities";

export const ExportForActivationOrderContext = createContext();

const ordersService = new OrdersService();

const ExportForActivationOrderProvider = ({ children, data, callback }) => {
  const [orders, setOrders] = useState(data);
  const [ordersError, setOrdersError] = useState(null);
  const [ordersLoader, setOrdersLoader] = useState(false);

  const exportOrders = () => {
    setOrdersLoader(true);
    ordersService
      .exportActivation(data)
      .then((response) => {
        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: "text/csv" })
        );
        const a = document.createElement("a");
        a.href = url;
        a.download = "orders_export_activation_" + Date.now() + ".csv";
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        setOrdersLoader(false);
        callback();
      })
      .catch((err) => {
        setOrdersError(err);
        setOrdersLoader(false);
      });
  };

  useEffect(() => {
    if (ordersError) {
      callErrorToast(ordersError);
    }
  }, [ordersError]);

  return (
    <ExportForActivationOrderContext.Provider
      value={{
        //#region Order
        orders,
        ordersError,
        ordersLoader,
        exportOrders,
        callback,
        //#endregion
      }}
    >
      {children}
    </ExportForActivationOrderContext.Provider>
  );
};

const useExportForActivationOrderContext = () => {
  return useContext(ExportForActivationOrderContext);
};

export { ExportForActivationOrderProvider, useExportForActivationOrderContext };
