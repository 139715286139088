import React, { useState, useEffect, useRef } from "react";
import { Card, CardBody } from "reactstrap";
import BreadcrumbItem from "../../Components/common/Breadcrumb";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import { ToastContainer, toast } from "react-toastify";
import { ShipmentsService } from "../../services/shipment-service/shipments";
import { AddressBookService } from "../../services/shipment-service/addressbook";
import { ShipmentSettingsService } from "../../services/registry/shipmentSettings";
import RateItem from "./RateItem";
import { useNavigate, useParams } from "react-router-dom";
import { Loader } from "../../Components/common/Loader";
import { useOidcUser } from "@axa-fr/react-oidc";
import { OrdersService } from "../../services/logistic/orders";
import { ShipmentSettingDetailsService } from "../../services/registry/shipmentSettingDetails";

const ordersService = new OrdersService();
const addressBookService = new AddressBookService();
const shipmentSettingsService = new ShipmentSettingsService();
const shipmentSettingDetailsService = new ShipmentSettingDetailsService();

const Rate = ({ callback }) => {
  const { id } = useParams();
  const { oidcUser } = useOidcUser();
  const [shipment, setShipment] = useState({});
  const [shipmentSetting, setShipmentSetting] = useState({});
  const [shipmentSettingDetails, setShipmentSettingDetails] = useState([]);
  const [pickup, setPickup] = useState(null);
  const [delivery, setDelivery] = useState(null);
  const [rateList, setRateList] = useState([]);
  const [attachmentTypes, setAttachmentTypes] = useState([]);
  const [loadingItem, setLoadingItem] = useState(false);
  const [loadingAll, setLoadingAll] = useState(false);
  const [updateRate, setUpdateRate] = useState(false);
  const navigate = useNavigate();

  const getRate = () => {
    ordersService
      .createShipment(id)
      .then((response) => {
        let rateList = [];
        if (response.data.rateList) {
          rateList = [...response.data.rateList];
          const ratesModule = response.data.rateList.length % 3;
          const missingRates =
            response.data.rateList.length < 3 ? 3 - ratesModule : 0;
          for (let i = 0; i < missingRates; i++) {
            rateList = [...rateList, {}];
          }
        }

        setRateList(rateList);
        setShipment(response.data.shipmentInfoDetail);
        setLoadingAll(false);
        setUpdateRate(false);

        getPickup(response.data.shipmentInfoDetail.pickupId);
        getDelivery(response.data.shipmentInfoDetail.deliveryId);
      })
      .catch((exception) => {
        toast.error(`Non ho recuperato le tariffe`, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

  const getPickup = (id) => {
    addressBookService
      .getAddress(id)
      .then((response) => {
        setPickup(response);
      })
      .catch((exception) => {
        toast.error(`Non ho recuperato il mittente`, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

  const getDelivery = (id) => {
    addressBookService
      .getAddress(id)
      .then((response) => {
        setDelivery(response);
      })
      .catch((exception) => {
        toast.error(`Non ho recuperato il destinatario`, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

  const getShipmentSettingDetails = (shipmentSettingId) => {
    shipmentSettingDetailsService
      .all({
        search: [
          {
            selector: "shipmentSettingId",
            value: shipmentSettingId,
            preciseSearch: true,
          },
        ],
      })
      .then((response) => {
        setShipmentSettingDetails(response?.data?.content || []);
      })
      .catch((err) => {
        let message = "";
        if (err.code === "ERR_NETWORK") {
          message = "Errore di rete, verifica la connessione.";
        }
        switch (err.response.status) {
          case 400:
            message = "400 - BadRequest";
            break;
          case 404:
            message = "404 - Impostazione spedizione non trovata.";
            break;
          case 500:
            message = "500 - Internal server error.";
            break;
        }
        toast.error(message, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

  const getShipmentSetting = () => {
    shipmentSettingsService
      .all({
        search: [
          {
            selector: "ownerId",
            value: oidcUser.owner,
            preciseSearch: true,
          },
        ],
      })
      .then((response) => {
        if (response.data?.content) {
          const shipmentSetting = response.data.content[0];
          getShipmentSettingDetails(shipmentSetting.id);
          setShipmentSetting(shipmentSetting);
        }
      })
      .catch((err) => {
        let message = "";
        if (err.code === "ERR_NETWORK") {
          message = "Errore di rete, verifica la connessione.";
        }
        switch (err.response.status) {
          case 400:
            message = "400 - BadRequest";
            break;
          case 404:
            message = "404 - Impostazione spedizione non trovata.";
            break;
          case 500:
            message = "500 - Internal server error.";
            break;
        }
        toast.error(message, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

  useEffect(() => {
    if (oidcUser) {
      getShipmentSetting();
    }
  }, [oidcUser]);

  useEffect(() => {
    getRate();
  }, []);

  return (
    <React.Fragment>
      <div className="content" id="container">
        {loadingAll ? (
          <Loader show={loadingAll} />
        ) : rateList.length > 0 ? (
          <Slide
            infinite={false}
            autoplay={false}
            transitionDuration={300}
            arrows={rateList.length > 3}
            canSwipe={rateList.length > 3}
            indicators={rateList.length > 3}
            responsive={[
              {
                breakpoint: 800,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 3,
                },
              },
              {
                breakpoint: 500,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 2,
                },
              },
            ]}
          >
            {rateList.length > 0 &&
              rateList.map((elem, index) => {
                const shipmentSettingDetail =
                  shipmentSettingDetails.find(
                    (x) =>
                      x.carrierService?.code ===
                      elem.contract?.carrierServiceCode
                  ) || null;

                return (
                  <RateItem
                    callback={callback}
                    key={index}
                    data={elem}
                    shipmentId={id}
                    shipmentData={shipment}
                    attachmentTypesList={attachmentTypes}
                    setLoadingAll={setLoadingAll}
                    loadingAll={loadingAll}
                    setLoadingItem={setLoadingItem}
                    loadingItem={loadingItem}
                    getRate={getRate}
                    updateRate={updateRate}
                    setUpdateRate={setUpdateRate}
                    pickup={pickup}
                    delivery={delivery}
                    shipmentSetting={shipmentSetting}
                    shipmentSettingDetail={shipmentSettingDetail}
                  />
                );
              })}
          </Slide>
        ) : (
          <Card className="mt-3">
            <CardBody>
              <h5 className="text-center mb-0">Nessuna tariffa trovata</h5>
            </CardBody>
          </Card>
        )}
      </div>
    </React.Fragment>
  );
};

export default Rate;
