import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

import { callErrorToast } from "../../../utilities";
import { ShipmentSettingsService } from "../../../services/registry/shipmentSettings";
import { useOidcAccessToken } from "@axa-fr/react-oidc";

export const LogisticRateContext = createContext();

const shipmentSettingsService = new ShipmentSettingsService();

const LogisticRateProvider = ({ children, callback, parentId }) => {
  const { accessTokenPayload } = useOidcAccessToken();

  const [rate, setRate] = useState();
  const [rateError, setRateError] = useState(null);
  const [rateLoader, setRateLoader] = useState(false);

  const [shipmentSetting, setShipmentSetting] = useState();
  const [pickup, setPickup] = useState();
  const [delivery, setDelivery] = useState();

  const [rateItemsLoader, setRateItemsLoader] = useState(false);

  const prevRateError = useRef();

  const getRate = () => {
    const rate = {
      shipmentInfoDetail: {
        volume: 0.006,
        weight: 1.0,
        shipmentType: "EXPRESS",
        parcelTypeCode: "PKG",
        parcelCount: 1,
        pickupId: "c5a52d14-7e08-4673-b8f9-38156bf322ae",
        deliveryId: "30812a56-5e9b-4f84-9219-bec6691662cf",
        options: [
          {
            code: "DY_SAT",
          },
        ],
      },
      rateList: [
        {
          costs: [
            {
              code: "NOLO",
              name: "Nolo",
              price: 1.0,
              time: 24,
            },
            {
              code: "FUEL_SRCH",
              name: "Fuel surcharge",
              price: 0.0,
            },
          ],
          contract: {
            contractId: "7c606279-8b14-4cba-94f6-8a528c2c1a6e",
            contractName: "SDA Passivo ✅",
            serviceId: "d9233c23-e367-485b-adae-855b5ce1c026",
            serviceName: "SDA Extra-large passivo",
            carrierServiceName: "SDA Extra-large",
            carrierServiceCode: "SDA_EXTRALARGE",
            carrierLogoUrl:
              "https://www.sda.it/SDAitThemeStatic/SDAitTheme/risorse_dt/condivise/immagini/loghi/logo-sda.png",
            carrierName: "SDA Italia",
            carrierCode: "SDA_IT",
            carrierAddressValidationEnabled: false,
            rowId: "0492902a-9a94-4f2a-8a1c-c45f266698d7",
            rowWeightStart: 0.0,
            rowWeightEnd: 20.0,
            taxedWeight: 1.0,
          },
          options: [
            {
              code: "DY_SAT",
              name: "Consegna il Sabato",
              price: 10.0,
            },
          ],
          noloCost: 1.0,
          totalCost: 1.0,
          noloTime: 24,
          totalTime: 24,
        },
        {
          costs: [
            {
              code: "NOLO",
              name: "Nolo",
              price: 1.0,
              time: 0,
            },
            {
              code: "FUEL_SRCH",
              name: "Fuel Surcharge",
              price: 0.15,
            },
          ],
          contract: {
            contractId: "15ed2760-3f22-4dda-a7b3-3a07fa7175a8",
            contractName: "GLS PASSIVO ✅",
            serviceId: "c8ec0c3f-da2e-4713-9c35-90b48fcaf898",
            serviceName: "GLS",
            carrierServiceName: "Distribuzione Nazionale",
            carrierServiceCode: "GLS_DOM",
            carrierLogoUrl: "https://shorturl.at/ASVZ2",
            carrierName: "GLS Italia",
            carrierCode: "GLS_IT",
            carrierAddressValidationEnabled: true,
            rowId: "734bf2db-2458-4304-8fb1-4a270b945a4e",
            rowWeightStart: 0.0,
            rowWeightEnd: 1.0,
            taxedWeight: 1.0,
            volumetricWeight: 0.6,
            serviceWeightVolumeRatio: 100,
          },
          options: [
            {
              code: "DY_ON_APMT",
              name: "Consegna su appuntamento",
              price: 1.5,
            },
            {
              code: "DY_SAT",
              name: "Consegna il Sabato",
              price: 2.5,
            },
          ],
          noloCost: 1.0,
          totalCost: 1.15,
          noloTime: 0,
          totalTime: 0,
        },
      ],
    };

    setRate(rate);
  };

  const getShipmentSettings = () => {
    shipmentSettingsService
      .all({
        search: [
          {
            selector: "ownerId",
            value: accessTokenPayload.owner,
            preciseSearch: true,
          },
        ],
      })
      .then((response) => {
        if (response.data?.content) {
          setShipmentSetting(response.data.content[0]);
        }
      })
      .catch((err) => {
        setRateError(err);
      });
  };

  useEffect(() => {
    if (
      rateError &&
      JSON.stringify(prevRateError.current) !== JSON.stringify(rateError)
    ) {
      callErrorToast(rateError);
    }
  }, [rateError]);

  useEffect(() => {
    getRate();
    getShipmentSettings();
  }, []);

  return (
    <LogisticRateContext.Provider
      value={{
        rate,
        rateError,
        rateLoader,
        rateItemsLoader,

        pickup,
        delivery,
        shipmentSetting,
        setRateItemsLoader,

        getRate,

        parentId,
        callback,
      }}
    >
      {children}
    </LogisticRateContext.Provider>
  );
};

const useLogisticRateContext = () => {
  return useContext(LogisticRateContext);
};

export { LogisticRateProvider, useLogisticRateContext };
