import React from "react";
import { useLocation } from "react-router-dom";
import { convertFromJson } from "fiql-query-builder";
import moment from "moment";
import "moment/locale/it";
import { toast } from "react-toastify";

export const modalSettingsDefault = {
  open: false,
  title: "",
  icon: "",
  action: "",
};

export const checkErrors = (prop, errors, callback) => {
  if (errors) {
    const newErrors = { ...errors };
    delete newErrors[prop];
    callback(newErrors);
  }
};

export const createQueryString = (url, filters) => {
  //fiql
  if (filters.search) {
    const search = filters.search;
    url += "search=";
    for (let property in search) {
      if (search[property] !== "") {
        url += `${property}%3D%3D${search[property]}%3B`;
      }
    }
    url = url.substring(0, url.length - 3);
  }

  if (filters.sort) {
    url += `&sort=${filters.sort.property},${filters.sort.mode}`;
  }

  if (filters.freeSearch) {
    // for (let property in filters) {
    //   if (filters[property] !== "") {
    //     url += `&${property}=${filters[property]}`;
    //   }
    // }
    url += `&freeSearch=${filters.freeSearch}`;
  }

  return url;
};

export const hasRole = (rolesList, role) => {
  if (rolesList && role) {
    return rolesList.find((elem) => elem === role) || false;
  }
  return true;
};

export const calcSingleVolume = (width, length, height) => {
  if (width && length && height) {
    return ((length * height * width) / 1000000).toFixed(6);
  }
  return "";
};

export const writeLabelAddress = (location) => {
  let address = "";
  if (location?.street) address += location?.street;
  if (location?.houseNumber) address += ", " + location?.houseNumber;
  if (location?.postalCode) address += ", " + location?.postalCode;
  if (location?.municipality) address += ", " + location?.municipality;
  if (location?.subRegionName) address += " (" + location?.subRegionName + ")";
  if (location?.countryIsoCode3) address += ", " + location?.countryIsoCode3;
  return address;
};
export const locationNormalizerForZone = (location) => {
  if (location) {
    const label = location.label;
    let loc = locationNormalizer(location);
    loc.label = label;
    return loc;
  }
  return null;
};
export const locationNormalizer = (location) => {
  if (location) {
    const place = location.place.Place;
    const locationNormalized = {
      countryIsoCode3: place.Country || null,
      houseNumber: place.AddressNumber || null,
      postalCode: place.PostalCode || null,
      street: place.Street || null,
      municipality: place.Municipality || null,
      district: place.Neighborhood || null,
      subRegion: place.SubRegion || null,
      subRegionName: place.SubRegion || null,
      label: place.Label || null,
      region: place.Region || null,
      regionName: place.Region || null,
      unit: place.Unit || null,
      languageCode: "IT" || null,
      matchLevelCode: place.Categories[0] || null,
      latitude: place.Geometry.Point[0] || null,
      longitude: place.Geometry.Point[1] || null,
    };

    locationNormalized.label = writeLabelAddress(locationNormalized);
    return locationNormalized;
  }
  return null;
};

export const shipmentValidator = (shipment) => {
  let errors = [];

  //Pickup
  // if (!shipment.pickup.location.countryName) {
  //   errors.push({
  //     section: "Mittente",
  //     error: "Il countryName non può essere nullo",
  //   });
  // }
  if (!shipment.pickup.name) {
    errors.push({
      section: "Mittente",
      error: "Il name non può essere nullo",
    });
  }

  //Delivery
  // if (!shipment.delivery.location.countryName) {
  //   errors.push({
  //     section: "Destinatario",
  //     error: "Il countryName non può essere nullo",
  //   });
  // }
  if (!shipment.delivery.name) {
    errors.push({
      section: "Destinatario",
      error: "Il name non può essere nullo",
    });
  }

  //Shipment
  if (!shipment.parcelType) {
    errors.push({
      section: "Tipologia collo",
      error: "Il parcelType non può essere nullo",
    });
  }
  //Generic fields
  shipment.customizations.forEach((elem) => {
    if (!elem.rank || !elem.value) {
      errors.push({
        section: "Campi aggiuntivi",
        error: "I campi rank e value sono obbligatori",
      });
      return false;
    }
  });
  for (let i = 0; i < shipment.customizations.length; i++) {
    for (let j = i + 1; j < shipment.customizations.length; j++) {
      if (shipment.customizations[i].rank == shipment.customizations[j].rank) {
        errors.push({
          section: "Campi aggiuntivi",
          error: "Il rank dei campi aggiuntivi dev'essere univoco",
        });
      }
    }
  }

  //Parcels
  if (shipment.parcels.length === 0) {
    errors.push({
      section: "Colli",
      error: "Nella spedizione dev'essere presente almeno un collo",
    });
  } else {
    shipment.parcels.every((elem) => {
      if (
        !elem.width ||
        !elem.length ||
        !elem.height ||
        !elem.weight ||
        !elem.quantity
      ) {
        errors.push({
          section: "Colli",
          error:
            "I campi Profondità, larghezza, altezza, peso e quantità sono obbligatori",
        });
        return false;
      }
    });
  }

  return errors;
};

export const euCountries = [
  "AUT",
  "BEL",
  "BGR",
  "HRV",
  "CYP",
  "CZE",
  "DNK",
  "EST",
  "FIN",
  "FRA",
  "DEU",
  "GRC",
  "HUN",
  "IRL",
  "ITA",
  "LVA",
  "LTU",
  "LUX",
  "MLT",
  "NLD",
  "POL",
  "PRT",
  "ROU",
  "SMR",
  "SVK",
  "SVN",
  "ESP",
  "SWE",
  "BE",
  "EL",
  "LT",
  "PT",
  "BG",
  "ES",
  "LU",
  "RO",
  "CZ",
  "FR",
  "HU",
  "SI",
  "DK",
  "HR",
  "MT",
  "SK",
  "DE",
  "IT",
  "NL",
  "FI",
  "EE",
  "CY",
  "AT",
  "SE",
  "SM",
  "IE",
  "LV",
  "PL",
];

export const countries = [
  { code: "GB", value: "United Kingdom" },
  { code: "AF", value: "Afghanistan" },
  { code: "AX", value: "Aland Islands" },
  { code: "AL", value: "Albania" },
  { code: "DZ", value: "Algeria" },
  { code: "AS", value: "American Samoa" },
  { code: "AD", value: "Andorra" },
  { code: "AO", value: "Angola" },
  { code: "AI", value: "Anguilla" },
  { code: "AQ", value: "Antarctica" },
  { code: "AG", value: "Antigua And Barbuda" },
  { code: "AR", value: "Argentina" },
  { code: "AM", value: "Armenia" },
  { code: "AN", value: "Netherlands Antilles" },
  { code: "AW", value: "Aruba" },
  { code: "AU", value: "Australia" },
  { code: "AT", value: "Austria" },
  { code: "AZ", value: "Azerbaijan" },
  { code: "BS", value: "Bahamas" },
  { code: "BH", value: "Bahrain" },
  { code: "BD", value: "Bangladesh" },
  { code: "BB", value: "Barbados" },
  { code: "BY", value: "Belarus" },
  { code: "BE", value: "Belgium" },
  { code: "BZ", value: "Belize" },
  { code: "BJ", value: "Benin" },
  { code: "BM", value: "Bermuda" },
  { code: "BT", value: "Bhutan" },
  { code: "BO", value: "Bolivia" },
  { code: "BA", value: "Bosnia And Herzegovina" },
  { code: "BW", value: "Botswana" },
  { code: "BV", value: "Bouvet Island" },
  { code: "BR", value: "Brazil" },
  { code: "IO", value: "British Indian Ocean Territory" },
  { code: "BN", value: "Brunei Darussalam" },
  { code: "BG", value: "Bulgaria" },
  { code: "BF", value: "Burkina Faso" },
  { code: "BI", value: "Burundi" },
  { code: "KH", value: "Cambodia" },
  { code: "CM", value: "Cameroon" },
  { code: "CA", value: "Canada" },
  { code: "CV", value: "Cape Verde" },
  { code: "KY", value: "Cayman Islands" },
  { code: "CF", value: "Central African Republic" },
  { code: "TD", value: "Chad" },
  { code: "CL", value: "Chile" },
  { code: "CN", value: "China" },
  { code: "CX", value: "Christmas Island" },
  { code: "CC", value: "Cocos (Keeling) Islands" },
  { code: "CO", value: "Colombia" },
  { code: "KM", value: "Comoros" },
  { code: "CG", value: "Congo" },
  { code: "CD", value: "Congo, Democratic Republic" },
  { code: "CK", value: "Cook Islands" },
  { code: "CR", value: "Costa Rica" },
  { code: "CI", value: 'Cote D"Ivoire' },
  { code: "HR", value: "Croatia" },
  { code: "CU", value: "Cuba" },
  { code: "CY", value: "Cyprus" },
  { code: "CZ", value: "Czech Republic" },
  { code: "DK", value: "Denmark" },
  { code: "DJ", value: "Djibouti" },
  { code: "DM", value: "Dominica" },
  { code: "DO", value: "Dominican Republic" },
  { code: "EC", value: "Ecuador" },
  { code: "EG", value: "Egypt" },
  { code: "SV", value: "El Salvador" },
  { code: "GQ", value: "Equatorial Guinea" },
  { code: "ER", value: "Eritrea" },
  { code: "EE", value: "Estonia" },
  { code: "ET", value: "Ethiopia" },
  { code: "FK", value: "Falkland Islands (Malvinas)" },
  { code: "FO", value: "Faroe Islands" },
  { code: "FJ", value: "Fiji" },
  { code: "FI", value: "Finland" },
  { code: "FR", value: "France" },
  { code: "GF", value: "French Guiana" },
  { code: "PF", value: "French Polynesia" },
  { code: "TF", value: "French Southern Territories" },
  { code: "GA", value: "Gabon" },
  { code: "GM", value: "Gambia" },
  { code: "GE", value: "Georgia" },
  { code: "DE", value: "Germany" },
  { code: "GH", value: "Ghana" },
  { code: "GI", value: "Gibraltar" },
  { code: "GR", value: "Greece" },
  { code: "GL", value: "Greenland" },
  { code: "GD", value: "Grenada" },
  { code: "GP", value: "Guadeloupe" },
  { code: "GU", value: "Guam" },
  { code: "GT", value: "Guatemala" },
  { code: "GG", value: "Guernsey" },
  { code: "GN", value: "Guinea" },
  { code: "GW", value: "Guinea-Bissau" },
  { code: "GY", value: "Guyana" },
  { code: "HT", value: "Haiti" },
  { code: "HM", value: "Heard Island & Mcdonald Islands" },
  { code: "VA", value: "Holy See (Vatican City State)" },
  { code: "HN", value: "Honduras" },
  { code: "HK", value: "Hong Kong" },
  { code: "HU", value: "Hungary" },
  { code: "IS", value: "Iceland" },
  { code: "IN", value: "India" },
  { code: "ID", value: "Indonesia" },
  { code: "IR", value: "Iran, Islamic Republic Of" },
  { code: "IQ", value: "Iraq" },
  { code: "IE", value: "Ireland" },
  { code: "IM", value: "Isle Of Man" },
  { code: "IL", value: "Israel" },
  { code: "IT", value: "Italy" },
  { code: "JM", value: "Jamaica" },
  { code: "JP", value: "Japan" },
  { code: "JE", value: "Jersey" },
  { code: "JO", value: "Jordan" },
  { code: "KZ", value: "Kazakhstan" },
  { code: "KE", value: "Kenya" },
  { code: "KI", value: "Kiribati" },
  { code: "KR", value: "Korea" },
  { code: "KW", value: "Kuwait" },
  { code: "KG", value: "Kyrgyzstan" },
  { code: "LA", value: 'Lao People"s Democratic Republic' },
  { code: "LV", value: "Latvia" },
  { code: "LB", value: "Lebanon" },
  { code: "LS", value: "Lesotho" },
  { code: "LR", value: "Liberia" },
  { code: "LY", value: "Libyan Arab Jamahiriya" },
  { code: "LI", value: "Liechtenstein" },
  { code: "LT", value: "Lithuania" },
  { code: "LU", value: "Luxembourg" },
  { code: "MO", value: "Macao" },
  { code: "MK", value: "Macedonia" },
  { code: "MG", value: "Madagascar" },
  { code: "MW", value: "Malawi" },
  { code: "MY", value: "Malaysia" },
  { code: "MV", value: "Maldives" },
  { code: "ML", value: "Mali" },
  { code: "MT", value: "Malta" },
  { code: "MH", value: "Marshall Islands" },
  { code: "MQ", value: "Martinique" },
  { code: "MR", value: "Mauritania" },
  { code: "MU", value: "Mauritius" },
  { code: "YT", value: "Mayotte" },
  { code: "MX", value: "Mexico" },
  { code: "FM", value: "Micronesia, Federated States Of" },
  { code: "MD", value: "Moldova" },
  { code: "MC", value: "Monaco" },
  { code: "MN", value: "Mongolia" },
  { code: "ME", value: "Montenegro" },
  { code: "MS", value: "Montserrat" },
  { code: "MA", value: "Morocco" },
  { code: "MZ", value: "Mozambique" },
  { code: "MM", value: "Myanmar" },
  { code: "NA", value: "Namibia" },
  { code: "NR", value: "Nauru" },
  { code: "NP", value: "Nepal" },
  { code: "NL", value: "Netherlands" },
  { code: "NC", value: "New Caledonia" },
  { code: "NZ", value: "New Zealand" },
  { code: "NI", value: "Nicaragua" },
  { code: "NE", value: "Niger" },
  { code: "NG", value: "Nigeria" },
  { code: "NU", value: "Niue" },
  { code: "NF", value: "Norfolk Island" },
  { code: "MP", value: "Northern Mariana Islands" },
  { code: "NO", value: "Norway" },
  { code: "OM", value: "Oman" },
  { code: "PK", value: "Pakistan" },
  { code: "PW", value: "Palau" },
  { code: "PS", value: "Palestinian Territory, Occupied" },
  { code: "PA", value: "Panama" },
  { code: "PG", value: "Papua New Guinea" },
  { code: "PY", value: "Paraguay" },
  { code: "PE", value: "Peru" },
  { code: "PH", value: "Philippines" },
  { code: "PN", value: "Pitcairn" },
  { code: "PL", value: "Poland" },
  { code: "PT", value: "Portugal" },
  { code: "PR", value: "Puerto Rico" },
  { code: "QA", value: "Qatar" },
  { code: "RE", value: "Reunion" },
  { code: "RO", value: "Romania" },
  { code: "RU", value: "Russian Federation" },
  { code: "RW", value: "Rwanda" },
  { code: "BL", value: "Saint Barthelemy" },
  { code: "SH", value: "Saint Helena" },
  { code: "KN", value: "Saint Kitts And Nevis" },
  { code: "LC", value: "Saint Lucia" },
  { code: "MF", value: "Saint Martin" },
  { code: "PM", value: "Saint Pierre And Miquelon" },
  { code: "VC", value: "Saint Vincent And Grenadines" },
  { code: "WS", value: "Samoa" },
  { code: "SM", value: "San Marino" },
  { code: "ST", value: "Sao Tome And Principe" },
  { code: "SA", value: "Saudi Arabia" },
  { code: "SN", value: "Senegal" },
  { code: "RS", value: "Serbia" },
  { code: "SC", value: "Seychelles" },
  { code: "SL", value: "Sierra Leone" },
  { code: "SG", value: "Singapore" },
  { code: "SK", value: "Slovakia" },
  { code: "SI", value: "Slovenia" },
  { code: "SB", value: "Solomon Islands" },
  { code: "SO", value: "Somalia" },
  { code: "ZA", value: "South Africa" },
  { code: "GS", value: "South Georgia And Sandwich Isl." },
  { code: "ES", value: "Spain" },
  { code: "LK", value: "Sri Lanka" },
  { code: "SD", value: "Sudan" },
  { code: "SR", value: "Suriname" },
  { code: "SJ", value: "Svalbard And Jan Mayen" },
  { code: "SZ", value: "Swaziland" },
  { code: "SE", value: "Sweden" },
  { code: "CH", value: "Switzerland" },
  { code: "SY", value: "Syrian Arab Republic" },
  { code: "TW", value: "Taiwan" },
  { code: "TJ", value: "Tajikistan" },
  { code: "TZ", value: "Tanzania" },
  { code: "TH", value: "Thailand" },
  { code: "TL", value: "Timor-Leste" },
  { code: "TG", value: "Togo" },
  { code: "TK", value: "Tokelau" },
  { code: "TO", value: "Tonga" },
  { code: "TT", value: "Trinidad And Tobago" },
  { code: "TN", value: "Tunisia" },
  { code: "TR", value: "Turkey" },
  { code: "TM", value: "Turkmenistan" },
  { code: "TC", value: "Turks And Caicos Islands" },
  { code: "TV", value: "Tuvalu" },
  { code: "UG", value: "Uganda" },
  { code: "UA", value: "Ukraine" },
  { code: "AE", value: "United Arab Emirates" },
  { code: "US", value: "United States" },
  { code: "UM", value: "United States Outlying Islands" },
  { code: "UY", value: "Uruguay" },
  { code: "UZ", value: "Uzbekistan" },
  { code: "VU", value: "Vanuatu" },
  { code: "VE", value: "Venezuela" },
  { code: "VN", value: "Viet Nam" },
  { code: "VG", value: "Virgin Islands, British" },
  { code: "VI", value: "Virgin Islands, U.S." },
  { code: "WF", value: "Wallis And Futuna" },
  { code: "EH", value: "Western Sahara" },
  { code: "YE", value: "Yemen" },
  { code: "ZM", value: "Zambia" },
  { code: "ZW", value: "Zimbabwe" },
];

export const timezones = [
  {
    label: "US (Common)",
    options: [
      { value: "America/Puerto_Rico", label: "Puerto Rico (Atlantic)" },
      { value: "America/New_York", label: "New York (Eastern)" },
      { value: "America/Chicago", label: "Chicago (Central)" },
      { value: "America/Denver", label: "Denver (Mountain)" },
      { value: "America/Phoenix", label: "Phoenix (MST)" },
      { value: "America/Los_Angeles", label: "Los Angeles (Pacific)" },
      { value: "America/Anchorage", label: "Anchorage (Alaska)" },
      { value: "Pacific/Honolulu", label: "Honolulu (Hawaii)" },
    ],
  },
  {
    label: "America",
    options: [
      { value: "America/Adak", label: "Adak" },
      { value: "America/Anchorage", label: "Anchorage" },
      { value: "America/Anguilla", label: "Anguilla" },
      { value: "America/Antigua", label: "Antigua" },
      { value: "America/Araguaina", label: "Araguaina" },
      {
        value: "America/Argentina/Buenos_Aires",
        label: "Argentina - Buenos Aires",
      },
      { value: "America/Argentina/Catamarca", label: "Argentina - Catamarca" },
      {
        value: "America/Argentina/ComodRivadavia",
        label: "Argentina - ComodRivadavia",
      },
      { value: "America/Argentina/Cordoba", label: "Argentina - Cordoba" },
      { value: "America/Argentina/Jujuy", label: "Argentina - Jujuy" },
      { value: "America/Argentina/La_Rioja", label: "Argentina - La Rioja" },
      { value: "America/Argentina/Mendoza", label: "Argentina - Mendoza" },
      {
        value: "America/Argentina/Rio_Gallegos",
        label: "Argentina - Rio Gallegos",
      },
      { value: "America/Argentina/Salta", label: "Argentina - Salta" },
      { value: "America/Argentina/San_Juan", label: "Argentina - San Juan" },
      { value: "America/Argentina/San_Luis", label: "Argentina - San Luis" },
      { value: "America/Argentina/Tucuman", label: "Argentina - Tucuman" },
      { value: "America/Argentina/Ushuaia", label: "Argentina - Ushuaia" },
      { value: "America/Aruba", label: "Aruba" },
      { value: "America/Asuncion", label: "Asuncion" },
      { value: "America/Atikokan", label: "Atikokan" },
      { value: "America/Atka", label: "Atka" },
      { value: "America/Bahia", label: "Bahia" },
      { value: "America/Barbados", label: "Barbados" },
      { value: "America/Belem", label: "Belem" },
      { value: "America/Belize", label: "Belize" },
      { value: "America/Blanc-Sablon", label: "Blanc-Sablon" },
      { value: "America/Boa_Vista", label: "Boa Vista" },
      { value: "America/Bogota", label: "Bogota" },
      { value: "America/Boise", label: "Boise" },
      { value: "America/Buenos_Aires", label: "Buenos Aires" },
      { value: "America/Cambridge_Bay", label: "Cambridge Bay" },
      { value: "America/Campo_Grande", label: "Campo Grande" },
      { value: "America/Cancun", label: "Cancun" },
      { value: "America/Caracas", label: "Caracas" },
      { value: "America/Catamarca", label: "Catamarca" },
      { value: "America/Cayenne", label: "Cayenne" },
      { value: "America/Cayman", label: "Cayman" },
      { value: "America/Chicago", label: "Chicago" },
      { value: "America/Chihuahua", label: "Chihuahua" },
      { value: "America/Coral_Harbour", label: "Coral Harbour" },
      { value: "America/Cordoba", label: "Cordoba" },
      { value: "America/Costa_Rica", label: "Costa Rica" },
      { value: "America/Cuiaba", label: "Cuiaba" },
      { value: "America/Curacao", label: "Curacao" },
      { value: "America/Danmarkshavn", label: "Danmarkshavn" },
      { value: "America/Dawson", label: "Dawson" },
      { value: "America/Dawson_Creek", label: "Dawson Creek" },
      { value: "America/Denver", label: "Denver" },
      { value: "America/Detroit", label: "Detroit" },
      { value: "America/Dominica", label: "Dominica" },
      { value: "America/Edmonton", label: "Edmonton" },
      { value: "America/Eirunepe", label: "Eirunepe" },
      { value: "America/El_Salvador", label: "El Salvador" },
      { value: "America/Ensenada", label: "Ensenada" },
      { value: "America/Fortaleza", label: "Fortaleza" },
      { value: "America/Fort_Wayne", label: "Fort Wayne" },
      { value: "America/Glace_Bay", label: "Glace Bay" },
      { value: "America/Godthab", label: "Godthab" },
      { value: "America/Goose_Bay", label: "Goose Bay" },
      { value: "America/Grand_Turk", label: "Grand Turk" },
      { value: "America/Grenada", label: "Grenada" },
      { value: "America/Guadeloupe", label: "Guadeloupe" },
      { value: "America/Guatemala", label: "Guatemala" },
      { value: "America/Guayaquil", label: "Guayaquil" },
      { value: "America/Guyana", label: "Guyana" },
      { value: "America/Halifax", label: "Halifax" },
      { value: "America/Havana", label: "Havana" },
      { value: "America/Hermosillo", label: "Hermosillo" },
      {
        value: "America/Indiana/Indianapolis",
        label: "Indiana - Indianapolis",
      },
      { value: "America/Indiana/Knox", label: "Indiana - Knox" },
      { value: "America/Indiana/Marengo", label: "Indiana - Marengo" },
      { value: "America/Indiana/Petersburg", label: "Indiana - Petersburg" },
      { value: "America/Indiana/Tell_City", label: "Indiana - Tell City" },
      { value: "America/Indiana/Vevay", label: "Indiana - Vevay" },
      { value: "America/Indiana/Vincennes", label: "Indiana - Vincennes" },
      { value: "America/Indiana/Winamac", label: "Indiana - Winamac" },
      { value: "America/Indianapolis", label: "Indianapolis" },
      { value: "America/Inuvik", label: "Inuvik" },
      { value: "America/Iqaluit", label: "Iqaluit" },
      { value: "America/Jamaica", label: "Jamaica" },
      { value: "America/Jujuy", label: "Jujuy" },
      { value: "America/Juneau", label: "Juneau" },
      { value: "America/Kentucky/Louisville", label: "Kentucky - Louisville" },
      { value: "America/Kentucky/Monticello", label: "Kentucky - Monticello" },
      { value: "America/Knox_IN", label: "Knox IN" },
      { value: "America/La_Paz", label: "La Paz" },
      { value: "America/Lima", label: "Lima" },
      { value: "America/Los_Angeles", label: "Los Angeles" },
      { value: "America/Louisville", label: "Louisville" },
      { value: "America/Maceio", label: "Maceio" },
      { value: "America/Managua", label: "Managua" },
      { value: "America/Manaus", label: "Manaus" },
      { value: "America/Marigot", label: "Marigot" },
      { value: "America/Martinique", label: "Martinique" },
      { value: "America/Matamoros", label: "Matamoros" },
      { value: "America/Mazatlan", label: "Mazatlan" },
      { value: "America/Mendoza", label: "Mendoza" },
      { value: "America/Menominee", label: "Menominee" },
      { value: "America/Merida", label: "Merida" },
      { value: "America/Mexico_City", label: "Mexico City" },
      { value: "America/Miquelon", label: "Miquelon" },
      { value: "America/Moncton", label: "Moncton" },
      { value: "America/Monterrey", label: "Monterrey" },
      { value: "America/Montevideo", label: "Montevideo" },
      { value: "America/Montreal", label: "Montreal" },
      { value: "America/Montserrat", label: "Montserrat" },
      { value: "America/Nassau", label: "Nassau" },
      { value: "America/New_York", label: "New York" },
      { value: "America/Nipigon", label: "Nipigon" },
      { value: "America/Nome", label: "Nome" },
      { value: "America/Noronha", label: "Noronha" },
      { value: "America/North_Dakota/Center", label: "North Dakota - Center" },
      {
        value: "America/North_Dakota/New_Salem",
        label: "North Dakota - New Salem",
      },
      { value: "America/Ojinaga", label: "Ojinaga" },
      { value: "America/Panama", label: "Panama" },
      { value: "America/Pangnirtung", label: "Pangnirtung" },
      { value: "America/Paramaribo", label: "Paramaribo" },
      { value: "America/Phoenix", label: "Phoenix" },
      { value: "America/Port-au-Prince", label: "Port-au-Prince" },
      { value: "America/Porto_Acre", label: "Porto Acre" },
      { value: "America/Port_of_Spain", label: "Port of Spain" },
      { value: "America/Porto_Velho", label: "Porto Velho" },
      { value: "America/Puerto_Rico", label: "Puerto Rico" },
      { value: "America/Rainy_River", label: "Rainy River" },
      { value: "America/Rankin_Inlet", label: "Rankin Inlet" },
      { value: "America/Recife", label: "Recife" },
      { value: "America/Regina", label: "Regina" },
      { value: "America/Resolute", label: "Resolute" },
      { value: "America/Rio_Branco", label: "Rio Branco" },
      { value: "America/Rosario", label: "Rosario" },
      { value: "America/Santa_Isabel", label: "Santa Isabel" },
      { value: "America/Santarem", label: "Santarem" },
      { value: "America/Santiago", label: "Santiago" },
      { value: "America/Santo_Domingo", label: "Santo Domingo" },
      { value: "America/Sao_Paulo", label: "Sao Paulo" },
      { value: "America/Scoresbysund", label: "Scoresbysund" },
      { value: "America/Shiprock", label: "Shiprock" },
      { value: "America/St_Barthelemy", label: "St Barthelemy" },
      { value: "America/St_Johns", label: "St Johns" },
      { value: "America/St_Kitts", label: "St Kitts" },
      { value: "America/St_Lucia", label: "St Lucia" },
      { value: "America/St_Thomas", label: "St Thomas" },
      { value: "America/St_Vincent", label: "St Vincent" },
      { value: "America/Swift_Current", label: "Swift Current" },
      { value: "America/Tegucigalpa", label: "Tegucigalpa" },
      { value: "America/Thule", label: "Thule" },
      { value: "America/Thunder_Bay", label: "Thunder Bay" },
      { value: "America/Tijuana", label: "Tijuana" },
      { value: "America/Toronto", label: "Toronto" },
      { value: "America/Tortola", label: "Tortola" },
      { value: "America/Vancouver", label: "Vancouver" },
      { value: "America/Virgin", label: "Virgin" },
      { value: "America/Whitehorse", label: "Whitehorse" },
      { value: "America/Winnipeg", label: "Winnipeg" },
      { value: "America/Yakutat", label: "Yakutat" },
      { value: "America/Yellowknife", label: "Yellowknife" },
    ],
  },
  {
    label: "Europe",
    options: [
      { value: "Europe/Amsterdam", label: "Amsterdam" },
      { value: "Europe/Andorra", label: "Andorra" },
      { value: "Europe/Athens", label: "Athens" },
      { value: "Europe/Belfast", label: "Belfast" },
      { value: "Europe/Belgrade", label: "Belgrade" },
      { value: "Europe/Berlin", label: "Berlin" },
      { value: "Europe/Bratislava", label: "Bratislava" },
      { value: "Europe/Brussels", label: "Brussels" },
      { value: "Europe/Bucharest", label: "Bucharest" },
      { value: "Europe/Budapest", label: "Budapest" },
      { value: "Europe/Chisinau", label: "Chisinau" },
      { value: "Europe/Copenhagen", label: "Copenhagen" },
      { value: "Europe/Dublin", label: "Dublin" },
      { value: "Europe/Gibraltar", label: "Gibraltar" },
      { value: "Europe/Guernsey", label: "Guernsey" },
      { value: "Europe/Helsinki", label: "Helsinki" },
      { value: "Europe/Isle_of_Man", label: "Isle of Man" },
      { value: "Europe/Istanbul", label: "Istanbul" },
      { value: "Europe/Jersey", label: "Jersey" },
      { value: "Europe/Kaliningrad", label: "Kaliningrad" },
      { value: "Europe/Kiev", label: "Kiev" },
      { value: "Europe/Lisbon", label: "Lisbon" },
      { value: "Europe/Ljubljana", label: "Ljubljana" },
      { value: "Europe/London", label: "London" },
      { value: "Europe/Luxembourg", label: "Luxembourg" },
      { value: "Europe/Madrid", label: "Madrid" },
      { value: "Europe/Malta", label: "Malta" },
      { value: "Europe/Mariehamn", label: "Mariehamn" },
      { value: "Europe/Minsk", label: "Minsk" },
      { value: "Europe/Monaco", label: "Monaco" },
      { value: "Europe/Moscow", label: "Moscow" },
      { value: "Europe/Nicosia", label: "Nicosia" },
      { value: "Europe/Oslo", label: "Oslo" },
      { value: "Europe/Paris", label: "Paris" },
      { value: "Europe/Podgorica", label: "Podgorica" },
      { value: "Europe/Prague", label: "Prague" },
      { value: "Europe/Riga", label: "Riga" },
      { value: "Europe/Rome", label: "Rome" },
      { value: "Europe/Samara", label: "Samara" },
      { value: "Europe/San_Marino", label: "San Marino" },
      { value: "Europe/Sarajevo", label: "Sarajevo" },
      { value: "Europe/Simferopol", label: "Simferopol" },
      { value: "Europe/Skopje", label: "Skopje" },
      { value: "Europe/Sofia", label: "Sofia" },
      { value: "Europe/Stockholm", label: "Stockholm" },
      { value: "Europe/Tallinn", label: "Tallinn" },
      { value: "Europe/Tirane", label: "Tirane" },
      { value: "Europe/Tiraspol", label: "Tiraspol" },
      { value: "Europe/Uzhgorod", label: "Uzhgorod" },
      { value: "Europe/Vaduz", label: "Vaduz" },
      { value: "Europe/Vatican", label: "Vatican" },
      { value: "Europe/Vienna", label: "Vienna" },
      { value: "Europe/Vilnius", label: "Vilnius" },
      { value: "Europe/Volgograd", label: "Volgograd" },
      { value: "Europe/Warsaw", label: "Warsaw" },
      { value: "Europe/Zagreb", label: "Zagreb" },
      { value: "Europe/Zaporozhye", label: "Zaporozhye" },
      { value: "Europe/Zurich", label: "Zurich" },
    ],
  },
  {
    label: "Asia",
    options: [
      { value: "Asia/Aden", label: "Aden" },
      { value: "Asia/Almaty", label: "Almaty" },
      { value: "Asia/Amman", label: "Amman" },
      { value: "Asia/Anadyr", label: "Anadyr" },
      { value: "Asia/Aqtau", label: "Aqtau" },
      { value: "Asia/Aqtobe", label: "Aqtobe" },
      { value: "Asia/Ashgabat", label: "Ashgabat" },
      { value: "Asia/Ashkhabad", label: "Ashkhabad" },
      { value: "Asia/Baghdad", label: "Baghdad" },
      { value: "Asia/Bahrain", label: "Bahrain" },
      { value: "Asia/Baku", label: "Baku" },
      { value: "Asia/Bangkok", label: "Bangkok" },
      { value: "Asia/Beirut", label: "Beirut" },
      { value: "Asia/Bishkek", label: "Bishkek" },
      { value: "Asia/Brunei", label: "Brunei" },
      { value: "Asia/Calcutta", label: "Calcutta" },
      { value: "Asia/Choibalsan", label: "Choibalsan" },
      { value: "Asia/Chongqing", label: "Chongqing" },
      { value: "Asia/Chungking", label: "Chungking" },
      { value: "Asia/Colombo", label: "Colombo" },
      { value: "Asia/Dacca", label: "Dacca" },
      { value: "Asia/Damascus", label: "Damascus" },
      { value: "Asia/Dhaka", label: "Dhaka" },
      { value: "Asia/Dili", label: "Dili" },
      { value: "Asia/Dubai", label: "Dubai" },
      { value: "Asia/Dushanbe", label: "Dushanbe" },
      { value: "Asia/Gaza", label: "Gaza" },
      { value: "Asia/Harbin", label: "Harbin" },
      { value: "Asia/Ho_Chi_Minh", label: "Ho Chi Minh" },
      { value: "Asia/Hong_Kong", label: "Hong Kong" },
      { value: "Asia/Hovd", label: "Hovd" },
      { value: "Asia/Irkutsk", label: "Irkutsk" },
      { value: "Asia/Istanbul", label: "Istanbul" },
      { value: "Asia/Jakarta", label: "Jakarta" },
      { value: "Asia/Jayapura", label: "Jayapura" },
      { value: "Asia/Jerusalem", label: "Jerusalem" },
      { value: "Asia/Kabul", label: "Kabul" },
      { value: "Asia/Kamchatka", label: "Kamchatka" },
      { value: "Asia/Karachi", label: "Karachi" },
      { value: "Asia/Kashgar", label: "Kashgar" },
      { value: "Asia/Kathmandu", label: "Kathmandu" },
      { value: "Asia/Katmandu", label: "Katmandu" },
      { value: "Asia/Kolkata", label: "Kolkata" },
      { value: "Asia/Krasnoyarsk", label: "Krasnoyarsk" },
      { value: "Asia/Kuala_Lumpur", label: "Kuala Lumpur" },
      { value: "Asia/Kuching", label: "Kuching" },
      { value: "Asia/Kuwait", label: "Kuwait" },
      { value: "Asia/Macao", label: "Macao" },
      { value: "Asia/Macau", label: "Macau" },
      { value: "Asia/Magadan", label: "Magadan" },
      { value: "Asia/Makassar", label: "Makassar" },
      { value: "Asia/Manila", label: "Manila" },
      { value: "Asia/Muscat", label: "Muscat" },
      { value: "Asia/Nicosia", label: "Nicosia" },
      { value: "Asia/Novokuznetsk", label: "Novokuznetsk" },
      { value: "Asia/Novosibirsk", label: "Novosibirsk" },
      { value: "Asia/Omsk", label: "Omsk" },
      { value: "Asia/Oral", label: "Oral" },
      { value: "Asia/Phnom_Penh", label: "Phnom Penh" },
      { value: "Asia/Pontianak", label: "Pontianak" },
      { value: "Asia/Pyongyang", label: "Pyongyang" },
      { value: "Asia/Qatar", label: "Qatar" },
      { value: "Asia/Qyzylorda", label: "Qyzylorda" },
      { value: "Asia/Rangoon", label: "Rangoon" },
      { value: "Asia/Riyadh", label: "Riyadh" },
      { value: "Asia/Saigon", label: "Saigon" },
      { value: "Asia/Sakhalin", label: "Sakhalin" },
      { value: "Asia/Samarkand", label: "Samarkand" },
      { value: "Asia/Seoul", label: "Seoul" },
      { value: "Asia/Shanghai", label: "Shanghai" },
      { value: "Asia/Singapore", label: "Singapore" },
      { value: "Asia/Taipei", label: "Taipei" },
      { value: "Asia/Tashkent", label: "Tashkent" },
      { value: "Asia/Tbilisi", label: "Tbilisi" },
      { value: "Asia/Tehran", label: "Tehran" },
      { value: "Asia/Tel_Aviv", label: "Tel Aviv" },
      { value: "Asia/Thimbu", label: "Thimbu" },
      { value: "Asia/Thimphu", label: "Thimphu" },
      { value: "Asia/Tokyo", label: "Tokyo" },
      { value: "Asia/Ujung_Pandang", label: "Ujung Pandang" },
      { value: "Asia/Ulaanbaatar", label: "Ulaanbaatar" },
      { value: "Asia/Ulan_Bator", label: "Ulan Bator" },
      { value: "Asia/Urumqi", label: "Urumqi" },
      { value: "Asia/Vientiane", label: "Vientiane" },
      { value: "Asia/Vladivostok", label: "Vladivostok" },
      { value: "Asia/Yakutsk", label: "Yakutsk" },
      { value: "Asia/Yekaterinburg", label: "Yekaterinburg" },
      { value: "Asia/Yerevan", label: "Yerevan" },
    ],
  },
  {
    label: "Africa",
    options: [
      { value: "Africa/Abidjan", label: "Abidjan" },
      { value: "Africa/Accra", label: "Accra" },
      { value: "Africa/Addis_Ababa", label: "Addis Ababa" },
      { value: "Africa/Algiers", label: "Algiers" },
      { value: "Africa/Asmara", label: "Asmara" },
      { value: "Africa/Asmera", label: "Asmera" },
      { value: "Africa/Bamako", label: "Bamako" },
      { value: "Africa/Bangui", label: "Bangui" },
      { value: "Africa/Banjul", label: "Banjul" },
      { value: "Africa/Bissau", label: "Bissau" },
      { value: "Africa/Blantyre", label: "Blantyre" },
      { value: "Africa/Brazzaville", label: "Brazzaville" },
      { value: "Africa/Bujumbura", label: "Bujumbura" },
      { value: "Africa/Cairo", label: "Cairo" },
      { value: "Africa/Casablanca", label: "Casablanca" },
      { value: "Africa/Ceuta", label: "Ceuta" },
      { value: "Africa/Conakry", label: "Conakry" },
      { value: "Africa/Dakar", label: "Dakar" },
      { value: "Africa/Dar_es_Salaam", label: "Dar es Salaam" },
      { value: "Africa/Djibouti", label: "Djibouti" },
      { value: "Africa/Douala", label: "Douala" },
      { value: "Africa/El_Aaiun", label: "El Aaiun" },
      { value: "Africa/Freetown", label: "Freetown" },
      { value: "Africa/Gaborone", label: "Gaborone" },
      { value: "Africa/Harare", label: "Harare" },
      { value: "Africa/Johannesburg", label: "Johannesburg" },
      { value: "Africa/Kampala", label: "Kampala" },
      { value: "Africa/Khartoum", label: "Khartoum" },
      { value: "Africa/Kigali", label: "Kigali" },
      { value: "Africa/Kinshasa", label: "Kinshasa" },
      { value: "Africa/Lagos", label: "Lagos" },
      { value: "Africa/Libreville", label: "Libreville" },
      { value: "Africa/Lome", label: "Lome" },
      { value: "Africa/Luanda", label: "Luanda" },
      { value: "Africa/Lubumbashi", label: "Lubumbashi" },
      { value: "Africa/Lusaka", label: "Lusaka" },
      { value: "Africa/Malabo", label: "Malabo" },
      { value: "Africa/Maputo", label: "Maputo" },
      { value: "Africa/Maseru", label: "Maseru" },
      { value: "Africa/Mbabane", label: "Mbabane" },
      { value: "Africa/Mogadishu", label: "Mogadishu" },
      { value: "Africa/Monrovia", label: "Monrovia" },
      { value: "Africa/Nairobi", label: "Nairobi" },
      { value: "Africa/Ndjamena", label: "Ndjamena" },
      { value: "Africa/Niamey", label: "Niamey" },
      { value: "Africa/Nouakchott", label: "Nouakchott" },
      { value: "Africa/Ouagadougou", label: "Ouagadougou" },
      { value: "Africa/Porto-Novo", label: "Porto-Novo" },
      { value: "Africa/Sao_Tome", label: "Sao Tome" },
      { value: "Africa/Timbuktu", label: "Timbuktu" },
      { value: "Africa/Tripoli", label: "Tripoli" },
      { value: "Africa/Tunis", label: "Tunis" },
      { value: "Africa/Windhoek", label: "Windhoek" },
    ],
  },
  {
    label: "Australia",
    options: [
      { value: "Australia/ACT", label: "ACT" },
      { value: "Australia/Adelaide", label: "Adelaide" },
      { value: "Australia/Brisbane", label: "Brisbane" },
      { value: "Australia/Broken_Hill", label: "Broken Hill" },
      { value: "Australia/Canberra", label: "Canberra" },
      { value: "Australia/Currie", label: "Currie" },
      { value: "Australia/Darwin", label: "Darwin" },
      { value: "Australia/Eucla", label: "Eucla" },
      { value: "Australia/Hobart", label: "Hobart" },
      { value: "Australia/LHI", label: "LHI" },
      { value: "Australia/Lindeman", label: "Lindeman" },
      { value: "Australia/Lord_Howe", label: "Lord Howe" },
      { value: "Australia/Melbourne", label: "Melbourne" },
      { value: "Australia/North", label: "North" },
      { value: "Australia/NSW", label: "NSW" },
      { value: "Australia/Perth", label: "Perth" },
      { value: "Australia/Queensland", label: "Queensland" },
      { value: "Australia/South", label: "South" },
      { value: "Australia/Sydney", label: "Sydney" },
      { value: "Australia/Tasmania", label: "Tasmania" },
      { value: "Australia/Victoria", label: "Victoria" },
      { value: "Australia/West", label: "West" },
      { value: "Australia/Yancowinna", label: "Yancowinna" },
    ],
  },
  {
    label: "Indian",
    options: [
      { value: "Indian/Antananarivo", label: "Antananarivo" },
      { value: "Indian/Chagos", label: "Chagos" },
      { value: "Indian/Christmas", label: "Christmas" },
      { value: "Indian/Cocos", label: "Cocos" },
      { value: "Indian/Comoro", label: "Comoro" },
      { value: "Indian/Kerguelen", label: "Kerguelen" },
      { value: "Indian/Mahe", label: "Mahe" },
      { value: "Indian/Maldives", label: "Maldives" },
      { value: "Indian/Mauritius", label: "Mauritius" },
      { value: "Indian/Mayotte", label: "Mayotte" },
      { value: "Indian/Reunion", label: "Reunion" },
    ],
  },
  {
    label: "Atlantic",
    options: [
      { value: "Atlantic/Azores", label: "Azores" },
      { value: "Atlantic/Bermuda", label: "Bermuda" },
      { value: "Atlantic/Canary", label: "Canary" },
      { value: "Atlantic/Cape_Verde", label: "Cape Verde" },
      { value: "Atlantic/Faeroe", label: "Faeroe" },
      { value: "Atlantic/Faroe", label: "Faroe" },
      { value: "Atlantic/Jan_Mayen", label: "Jan Mayen" },
      { value: "Atlantic/Madeira", label: "Madeira" },
      { value: "Atlantic/Reykjavik", label: "Reykjavik" },
      { value: "Atlantic/South_Georgia", label: "South Georgia" },
      { value: "Atlantic/Stanley", label: "Stanley" },
      { value: "Atlantic/St_Helena", label: "St Helena" },
    ],
  },
  {
    label: "Pacific",
    options: [
      { value: "Pacific/Apia", label: "Apia" },
      { value: "Pacific/Auckland", label: "Auckland" },
      { value: "Pacific/Chatham", label: "Chatham" },
      { value: "Pacific/Easter", label: "Easter" },
      { value: "Pacific/Efate", label: "Efate" },
      { value: "Pacific/Enderbury", label: "Enderbury" },
      { value: "Pacific/Fakaofo", label: "Fakaofo" },
      { value: "Pacific/Fiji", label: "Fiji" },
      { value: "Pacific/Funafuti", label: "Funafuti" },
      { value: "Pacific/Galapagos", label: "Galapagos" },
      { value: "Pacific/Gambier", label: "Gambier" },
      { value: "Pacific/Guadalcanal", label: "Guadalcanal" },
      { value: "Pacific/Guam", label: "Guam" },
      { value: "Pacific/Honolulu", label: "Honolulu" },
      { value: "Pacific/Johnston", label: "Johnston" },
      { value: "Pacific/Kiritimati", label: "Kiritimati" },
      { value: "Pacific/Kosrae", label: "Kosrae" },
      { value: "Pacific/Kwajalein", label: "Kwajalein" },
      { value: "Pacific/Majuro", label: "Majuro" },
      { value: "Pacific/Marquesas", label: "Marquesas" },
      { value: "Pacific/Midway", label: "Midway" },
      { value: "Pacific/Nauru", label: "Nauru" },
      { value: "Pacific/Niue", label: "Niue" },
      { value: "Pacific/Norfolk", label: "Norfolk" },
      { value: "Pacific/Noumea", label: "Noumea" },
      { value: "Pacific/Pago_Pago", label: "Pago Pago" },
      { value: "Pacific/Palau", label: "Palau" },
      { value: "Pacific/Pitcairn", label: "Pitcairn" },
      { value: "Pacific/Ponape", label: "Ponape" },
      { value: "Pacific/Port_Moresby", label: "Port Moresby" },
      { value: "Pacific/Rarotonga", label: "Rarotonga" },
      { value: "Pacific/Saipan", label: "Saipan" },
      { value: "Pacific/Samoa", label: "Samoa" },
      { value: "Pacific/Tahiti", label: "Tahiti" },
      { value: "Pacific/Tarawa", label: "Tarawa" },
      { value: "Pacific/Tongatapu", label: "Tongatapu" },
      { value: "Pacific/Truk", label: "Truk" },
      { value: "Pacific/Wake", label: "Wake" },
      { value: "Pacific/Wallis", label: "Wallis" },
      { value: "Pacific/Yap", label: "Yap" },
    ],
  },
  {
    label: "Antarctica",
    options: [
      { value: "Antarctica/Casey", label: "Casey" },
      { value: "Antarctica/Davis", label: "Davis" },
      { value: "Antarctica/DumontDUrville", label: "DumontDUrville" },
      { value: "Antarctica/Macquarie", label: "Macquarie" },
      { value: "Antarctica/Mawson", label: "Mawson" },
      { value: "Antarctica/McMurdo", label: "McMurdo" },
      { value: "Antarctica/Palmer", label: "Palmer" },
      { value: "Antarctica/Rothera", label: "Rothera" },
      { value: "Antarctica/South_Pole", label: "South Pole" },
      { value: "Antarctica/Syowa", label: "Syowa" },
      { value: "Antarctica/Vostok", label: "Vostok" },
    ],
  },
  {
    label: "Arctic",
    options: [{ value: "Arctic/Longyearbyen", label: "Longyearbyen" }],
  },
  {
    label: "UTC",
    options: [{ value: "UTC", label: "UTC" }],
  },
  {
    label: "Manual Offsets",
    options: [
      { value: "UTC-12", label: "UTC-12" },
      { value: "UTC-11", label: "UTC-11" },
      { value: "UTC-10", label: "UTC-10" },
      { value: "UTC-9", label: "UTC-9" },
      { value: "UTC-8", label: "UTC-8" },
      { value: "UTC-7", label: "UTC-7" },
      { value: "UTC-6", label: "UTC-6" },
      { value: "UTC-5", label: "UTC-5" },
      { value: "UTC-4", label: "UTC-4" },
      { value: "UTC-3", label: "UTC-3" },
      { value: "UTC-2", label: "UTC-2" },
      { value: "UTC-1", label: "UTC-1" },
      { value: "UTC+0", label: "UTC+0" },
      { value: "UTC+1", label: "UTC+1" },
      { value: "UTC+2", label: "UTC+2" },
      { value: "UTC+3", label: "UTC+3" },
      { value: "UTC+4", label: "UTC+4" },
      { value: "UTC+5", label: "UTC+5" },
      { value: "UTC+6", label: "UTC+6" },
      { value: "UTC+7", label: "UTC+7" },
      { value: "UTC+8", label: "UTC+8" },
      { value: "UTC+9", label: "UTC+9" },
      { value: "UTC+10", label: "UTC+10" },
      { value: "UTC+11", label: "UTC+11" },
      { value: "UTC+12", label: "UTC+12" },
      { value: "UTC+13", label: "UTC+13" },
      { value: "UTC+14", label: "UTC+14" },
    ],
  },
];

export const geTimezoneLabel = (value) => {
  const allTimezoneValues = timezones
    .map((el) => {
      return [el.options];
    })
    .flat()
    .flat();

  return allTimezoneValues.filter((timezone) => timezone.value === value)[0];
};

export const getShipmentLabel = (value) => {
  let label = "";
  switch (value) {
    case "EXPRESS":
      label = "Spedizione espressa";
      break;
    case "EXPRESS_RETURN":
      label = "Spedizione di reso";
      break;
    case "POSTAL":
      label = "Spedizione postale";
      break;
  }
  return label;
};

export const getAttachmentTypeLabel = (value) => {
  let label = "";
  switch (value) {
    case "EXPLAB_PDF_A4":
      label = "Express Label PDF formato A4";
      break;
    case "EXPLAB_PDF_A5":
      label = "Express Label PDF formato A5";
      break;
    case "EXPLAB_PDF_A6":
      label = "Express Label PDF formato A6";
      break;
    case "EXPLAB_ZPL_A6":
      label = "Express Label ZPL formato A6";
      break;
    case "INVOICE_PDF_A4":
      label = "Fattura PDF A4";
      break;
    case "EXPLAB_GENERIC_ZPL_10x10":
      label = "Express Label generica ZPL 10x10";
      break;
  }
  return label;
};

export const getContractTypeLabel = (value) => {
  let label = "";
  switch (value) {
    case "ACTIVE":
      label = "Attivo";
      break;
    case "PASSIVE":
      label = "Passivo";
      break;
  }
  return label;
};

export const orderBykey = (arr, key) => {
  return arr.sort((a, b) => {
    if (typeof a[key] === "number" && typeof b[key] === "number") {
      return a[key] - b[key]; // confronto numerico
    } else {
      return String(a[key]).localeCompare(String(b[key])); // confronto come stringhe
    }
  });
};

export const validateAndShowErrors = async (formikInstance) => {
  try {
    const errors = await formikInstance.validateForm();

    if (Object.keys(errors).length > 0) {
      const touchedFields = {};
      for (const errorField in errors) {
        touchedFields[errorField] = true;
      }
      formikInstance.setTouched({
        ...formikInstance.touched,
        ...touchedFields,
      });
    }

    return Object.keys(errors).length === 0;
  } catch (error) {
    // Gestisci l'errore se la validazione fallisce
    console.error("Errore durante la validazione del modulo:", error);
    return false;
  }
};

export const translateLevel = (level) => {
  switch (level) {
    case "AddressType":
      return "Address";
    case "StreetType":
      return "Street";
    case "IntersectionType":
      return "Intersection";
    case "PointOfInterestType":
      return "PointOfInterest";
    case "CountryType":
      return "Country";
    case "RegionType":
      return "Region";
    case "SubRegionType":
      return "SubRegion";
    case "MunicipalityType":
      return "Municipality";
    case "NeighborhoodType":
      return "Neighborhood";
    case "PostalCodeType":
      return "PostalCode";
    default:
      break;
  }
};

export const shipmentTypes = [
  { code: "EXPRESS", label: "Espressa", border: "border-primary" },
  { code: "EXPRESS_RETURN", label: "Reso", border: "border-info" },
  { code: "POSTAL", label: "Postale", border: "border-yellow" },
];

export const orderTypes = [
  { value: "FORWARD", label: "Andata", border: "border-info" },
  { value: "RETURN", label: "Reso", border: "border-primary" },
];

export const orderStatus = [
  { value: "DRAFT", label: "Bozza", color: "#C8C6C6" },
  { value: "CONFIRMED", label: "Confermato", color: "#A5A3A3" },
  { value: "RECEIVED", label: "Ricevuto dal magazzino", color: "#ff8800" },
  { value: "CANCELLED", label: "Cancellato", color: "#E51212" },
  { value: "PROCESSING", label: "In lavorazione", color: "#70AFE2" },
  { value: "SHIPPED", label: "Spedito", color: "#5DBB85" },
  { value: "ERROR", label: "In errore", color: "#E51212" },
];

export const incomingGoodStatus = [
  { value: "DRAFT", label: "Bozza", color: "#C8C6C6" },
  { value: "CONFIRMED", label: "Confermato", color: "#A5A3A3" },
  { value: "RECEIVED", label: "Ricevuto dal magazzino", color: "#ff8800" },
  { value: "PROCESSING", label: "In lavorazione", color: "#70AFE2" },
  { value: "COMPLETED", label: "Completato", color: "#5DBB85" },
];

export const getShipmentType = (type) => {
  const shipmentType = shipmentTypes.find((item) => item.code === type);
  return shipmentType ? shipmentType.label : "";
};

export const getShipmentBadgeClass = (type) => {
  const shipmentType = shipmentTypes.find((item) => item.code === type);
  return shipmentType ? shipmentType.border : "";
};

export const getShipmentTypeBadge = (type) => {
  return (
    <span
      className={`badge bg-light border-start border-width-3 text-body rounded-start-0 ${getShipmentBadgeClass(
        type
      )}`}
    >
      {getShipmentType(type)}
    </span>
  );
};

export const getOrderTypeBadge = (type) => {
  const orderType = orderTypes.find((elem) => elem.value === type);
  return (
    <span
      className={`badge bg-light border-start border-width-3 text-body rounded-start-0 ${orderType.border}`}
    >
      {orderType.label}
    </span>
  );
};

export const getParcelTypeBadge = (type, titleVisible = true) => {
  const code = type.code || type.value;
  const name = type.name || type.label;
  switch (code) {
    case "PKG":
      return (
        <span
          title={titleVisible ? name : ""}
          className="badge bg-light text-body rounded-pill p-1 me-2"
        >
          <i className="ph-package"></i>
        </span>
      );
    case "ENV":
      return (
        <span
          title={name}
          className="badge bg-light text-body rounded-pill p-1 me-2"
        >
          <i className="ph-envelope"></i>
        </span>
      );
    case "PAL":
      return (
        <span
          title={name}
          className="badge bg-light text-body rounded-pill p-1 me-2"
        >
          <i className="ph-equalizer"></i>
        </span>
      );
    case "DOC":
      return (
        <span
          title={name}
          className="badge bg-light text-body rounded-pill p-1 me-2"
        >
          <i className="ph-folders"></i>
        </span>
      );
  }
};

export const getOrderStatusBadge = (code) => {
  const status = orderStatus.find((elem) => elem.value === code);
  return (
    <span
      style={{ backgroundColor: status?.color }}
      className="badge"
      title={status?.label}
    >
      {status?.label}
    </span>
  );
};

export const getIncomingGoodStatusBadge = (code) => {
  const status = incomingGoodStatus.find((elem) => elem.value === code);
  return (
    <span
      style={{ backgroundColor: status?.color }}
      className="badge"
      title={status?.label}
    >
      {status?.label}
    </span>
  );
};

export const getLocationLevelBadge = (level) => {
  switch (level) {
    case "AddressType":
      return (
        <span style={{ backgroundColor: "#e6194B" }} className="badge">
          Address
        </span>
      );
    case "StreetType":
      return (
        <span style={{ backgroundColor: "#f58231" }} className="badge">
          Street
        </span>
      );
    case "IntersectionType":
      return (
        <span
          style={{ backgroundColor: "#ffe119" }}
          className="badge text-dark"
        >
          Intersection
        </span>
      );
    case "PointOfInterestType":
      return (
        <span
          style={{ backgroundColor: "#bfef45" }}
          className="badge text-dark"
        >
          PointOfInterest
        </span>
      );
    case "CountryType":
      return (
        <span style={{ backgroundColor: "#3cb44b" }} className="badge">
          Country
        </span>
      );
    case "RegionType":
      return (
        <span
          style={{ backgroundColor: "#42d4f4" }}
          className="badge text-dark"
        >
          Region
        </span>
      );
    case "SubRegionType":
      return (
        <span style={{ backgroundColor: "#4363d8" }} className="badge">
          SubRegion
        </span>
      );
    case "MunicipalityType":
      return (
        <span style={{ backgroundColor: "#911eb4" }} className="badge">
          Municipality
        </span>
      );
    case "NeighborhoodType":
      return (
        <span style={{ backgroundColor: "#f032e6" }} className="badge">
          Neighborhood
        </span>
      );
    case "PostalCodeType":
      return (
        <span style={{ backgroundColor: "#000075" }} className="badge">
          PostalCode
        </span>
      );

    default:
      break;
  }
};

export const getCompanyBadge = (companyTypeCode) => {
  switch (companyTypeCode) {
    case "CUSTOMER":
      return (
        <span className="badge bg-primary d-inline-block me-1">Cliente</span>
      );
    case "AFILIATE":
      return (
        <span className="badge bg-purple d-inline-block me-1">Affiliato</span>
      );
    case "ORGANIZATION":
      return (
        <span className="badge bg-yellow text-dark d-inline-block me-1">
          Organizzazione
        </span>
      );
    default:
      break;
  }
};

export const getCompanyLabel = (value) => {
  let label = "";
  switch (value) {
    case "ORGANIZATION":
      label = "Organizzazione";
      break;
    case "AFILIATE":
      label = "Affiliato";
      break;
    case "CUSTOMER":
      label = "Cliente";
      break;
  }
  return label;
};

export const getContractTypeBadge = (companyTypeCode) => {
  switch (companyTypeCode) {
    case "ACTIVE":
      return <span className="badge bg-purple">Attivo</span>;
    case "PASSIVE":
      return <span className="badge bg-yellow text-dark">Passivo</span>;
    default:
      break;
  }
};

export const getSurchargeType = (type) => {
  switch (type) {
    case "SURCHARGE":
      return <span className="badge bg-purple">Supplemento</span>;
    case "OPTION":
      return <span className="badge bg-yellow text-dark">Opzione</span>;
    default:
      break;
  }
};

export const contractTypes = [
  { value: "ACTIVE", label: "Attivo" },
  { value: "PASSIVE", label: "Passivo" },
];

export const carrierLogos = {
  TNT_IT:
    "https://upload.wikimedia.org/wikipedia/commons/thumb/c/c0/TNT_will_become_FedEx_Express_logo.svg/640px-TNT_will_become_FedEx_Express_logo.svg.png",
  DHL_IT:
    "https://www.dhl.com/content/dam/dhl/global/core/images/logos/dhl-logo.svg",
  BRT_IT:
    "https://www.brt.it/wp-content/uploads/sites/275/2021/07/BRT_logo_cropped.png",
  GLS_IT:
    "https://upload.wikimedia.org/wikipedia/commons/thumb/a/a6/GLS_Logo_2021.svg/2560px-GLS_Logo_2021.svg.png",
  UPS_IT: "https://www.ups.com/assets/resources/webcontent/images/ups-logo.svg",
  SDA_IT:
    "https://www.sda.it/SDAitThemeStatic/SDAitTheme/risorse_dt/condivise/immagini/loghi/logo-sda.png",
  SUSA: "https://flex.susa.it/TemplatesUSR/assets/img/testata/logo.jpg",
  ASENDIA_IT: "https://tracking.asendia.com/images/asendia.gif",
  KEPAG_DE: "",
  GB_IT:
    "https://lirp.cdn-website.com/181103e2/dms3rep/multi/opt/logo-552w.PNG",
  FEDEX_IT:
    "https://lh3.googleusercontent.com/YtXTsa-6SaaMl02-OUo8iRztlX5Thu4aCLavunIV1M5hm9y4ySTPpMjpY44fL4ayz7Se",
  ATF_IT: "",
  SITTAM: "https://www.interporto.it/data/upload/small/sittam.png",
  POSTPAC: "",
  GLS_INT:
    "https://upload.wikimedia.org/wikipedia/commons/thumb/a/a6/GLS_Logo_2021.svg/2560px-GLS_Logo_2021.svg.png",
  APLUS: "",
  BRK: "",
  DHL_DE_PKT:
    "https://www.dhl.com/content/dam/dhl/global/core/images/logos/dhl-logo.svg",
  YODEL:
    "https://s3-eu-west-1.amazonaws.com/tpd/logos/4d596fd100006400050eab79/0x0.png",
  POSTE_ITALIANE:
    "https://www.poste.it/img/1453895043057/logo-poste-italiane.png",
  SENDING: "",
  DHL_IT_FREIGHT:
    "https://www.dhl.com/content/dam/dhl/global/core/images/logos/dhl-logo.svg",
  DHL_INT_FREIGHT:
    "https://www.dhl.com/content/dam/dhl/global/core/images/logos/dhl-logo.svg",
  EPAQ: "https://www.asendia.it/hs-fs/hubfs/e-PAQ_logos-03.png?width=900&height=339&name=e-PAQ_logos-03.png",
  PRIME:
    "https://m.media-amazon.com/images/G/02/DSP2022/assets/desktop/blue-smile-logo.png",
  AMAZON_IT:
    "https://m.media-amazon.com/images/G/02/DSP2022/assets/desktop/blue-smile-logo.png",
  DHL_EXP:
    "https://www.dhl.com/content/dam/dhl/global/core/images/logos/dhl-logo.svg",
  SCL_IT: "https://www.elipse.it/assets/projects/scl/logo-principal.png",
  HRP_IT: "https://www.hrparcel.com/wp-content/uploads/2022/07/HR-PARCEL.svg",
  POSTEBUSINESS:
    "https://www.poste.it/img/1453895043057/logo-poste-italiane.png",
};

export const getUniqueSelectors = (
  selector,
  value,
  preciseSearch,
  queryParams,
  notEquals = false
) => {
  const query = [];
  const isObject = value instanceof Object;
  const isArray = Array.isArray(value);
  //Prendere una tipologia in in ingresso ed evitare tutti sti if

  if (isArray) {
    let date = new Date(value[0]);
    //Se è presente una data valida allora il filtro è datepicker
    if (isNaN(date.getTime())) {
      //Boolean
      if (value[0]?.value === "true" || value[0]?.value === "false") {
        const searchObj = {
          selector,
          args: "'<null>'",
        };
        if (value[0]?.isBool) {
          searchObj.args = value[0]?.value === "true" ? true : false;
          query.push({
            equals: searchObj,
          });
        } else {
          if (value[0].value === "true") {
            query.push({
              not_equals: searchObj,
            });
          } else {
            query.push({
              equals: searchObj,
            });
          }
        }
      } else {
        query.push({
          in: {
            selector,
            args:
              value == null ? "" : `(${value.map((x) => x.value).join(",")})`,
          },
        });
      }
    } else {
      query.push({
        and: [
          {
            greater_than_or_equal: {
              selector,
              args:
                value[0] == null
                  ? ""
                  : moment(value[0]).startOf("day").format(),
            },
          },
          {
            less_than_or_equal: {
              selector,
              args:
                value[1] == null ? "" : moment(value[1]).endOf("day").format(),
            },
          },
        ],
      });
    }
  } else if (isObject) {
    const searchObj = {
      selector,
      args:
        value.value == null
          ? ""
          : preciseSearch
          ? value.value
          : "*" + value.value + "*",
    };
    if (notEquals) {
      query.push({
        not_equals: searchObj,
      });
    } else {
      query.push({
        equals: searchObj,
      });
    }
  } else {
    const searchObj = {
      selector,
      args: preciseSearch ? "'" + value + "'" : "'*" + value + "*'",
    };
    if (notEquals) {
      query.push({
        not_equals: searchObj,
      });
    } else {
      query.push({
        equals: searchObj,
      });
    }
  }
  const arr = [...queryParams, ...query];

  const selectorMap = new Map();

  for (const item of arr) {
    const selector =
      item.equals?.selector ||
      item.in?.selector ||
      item.not_equals?.selector ||
      item.and?.[0]?.greater_than_or_equal?.selector;
    selectorMap.set(selector, item);
  }
  if (valueIsEmpty(value)) {
    selectorMap.delete(selector);
  }

  return Array.from(selectorMap.values());
};

export const buildQuerySearch = (query = []) => {
  return encodeURIComponent(convertFromJson({ and: [...query] }));
};

export const buildQuerySort = (sort = []) => {
  let querySort = "";
  sort.forEach((elem) => {
    querySort += `&sort=${elem.column},${elem.orderBy}`;
  });
  return querySort;
};

export const buildUrl = (url, params) => {
  const { pagination, sorting, search } = params;
  let selectors = [];

  if (search) {
    search.forEach((elem) => {
      selectors = getUniqueSelectors(
        elem.selector,
        elem.value,
        elem.preciseSearch,
        selectors,
        elem.notEquals
      );
    });
  }

  return `${url}?page=${pagination?.page || 0}&size=${pagination?.size || 20}${
    sorting ? `&sort=${sorting.selector},${sorting.order || "asc"}` : ""
  }${selectors.length > 0 ? `&search=${buildQuerySearch(selectors)}` : ""}`;
};

export const getNextWorkingDay = () => {
  const tomorrow = moment().add(1, "days"); // Calcola la data di domani
  let nextWorkingDay = tomorrow.clone();

  // domenica (0), passa al primo giorno lavorativo successivo
  if (nextWorkingDay.day() === 0) {
    // Domenica
    nextWorkingDay.add(1, "day");
  }

  // Altrimenti, la data di domani è già un giorno lavorativo
  return nextWorkingDay.toDate();
};

export const checkIfIsBusinessDay = () => {
  return moment().day() >= 1 && moment().day() <= 6;
};

export const valueIsEmpty = (value) => {
  if (typeof value === "number") return false;
  else if (typeof value === "string") return value.trim().length === 0;
  else if (Array.isArray(value)) return value.length === 0;
  else if (typeof value === "object")
    return value == null || Object.keys(value).length === 0;
  else if (typeof value === "boolean") return false;
  else return !value;
};

export const getClassForMultiActionElement = (statusOfTableRows) => {
  let labelClass = "";
  let iconClass = "invisible";
  let dropDownClass = "pe-none";
  if (!statusOfTableRows || statusOfTableRows.length === 0)
    return { labelClass, iconClass, dropDownClass };
  if (statusOfTableRows.some((element) => element.value)) {
    //almeno un valore selezionato
    labelClass = "scale-from-one-to-zero";
    iconClass = "scale-from-zero-to-one scale-animation-delay";
    dropDownClass = "pe-auto";
  } else {
    iconClass = "scale-from-one-to-zero";
    labelClass = "scale-from-zero-to-one scale-animation-delay";
    dropDownClass = "pe-none";
  }
  return { labelClass, iconClass, dropDownClass };
};

export const useQuery = () => {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
};

export const stringToColor = (str) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  let color = "#";
  for (let i = 0; i < 3; i++) {
    let value = (hash >> (i * 8)) & 0xff;
    color += ("00" + value.toString(16)).slice(-2);
  }
  return color;
};

export const isColorDark = (color) => {
  let r = parseInt(color.substr(1, 2), 16);
  let g = parseInt(color.substr(3, 2), 16);
  let b = parseInt(color.substr(5, 2), 16);
  let brightness = (r * 299 + g * 587 + b * 114) / 1000;
  return brightness < 128;
};

export const contactTypes = {
  SHP_PICKUP: "Punto di presa della spedizione",
};

export const callSuccessToast = (res) => {
  let message = "Salvataggio avvenuto con successo";
  switch (res.status) {
    case 200:
    case 201:
    case 204:
      message = res.message || message;
      break;
  }
  toast.success(message, {
    position: toast.POSITION.TOP_CENTER,
  });
};

export const callErrorToast = (err) => {
  let message = "";
  if (err.code === "ERR_NETWORK") {
    message = "Errore di rete, verifica la connessione.";
  }

  switch (err?.response?.status) {
    case 400:
      message =
        "400 - BadRequest - " +
        (err.response.data
          ? JSON.stringify(err.response.data)
          : err.response?.message || "");
      break;
    case 404:
      message = "404 - Not found";
      break;
    case 500:
      message = "500 - Internal server error.";
      break;
    default:
      message = "Internal web error. " + err;
  }
  toast.error(message, {
    position: toast.POSITION.TOP_CENTER,
  });
};

export const createFiltersObject = (
  inputValue,
  pagination,
  sorting,
  search = []
) => {
  let filters = {
    sorting,
    pagination,
    search,
  };
  if (inputValue) {
    filters.search = [
      ...filters.search,
      { selector: sorting.selector, value: inputValue },
    ];
  }
  return filters;
};
export const generateNumberObjects = (start, end) => {
  return Array.from({ length: end - start + 1 }, (_, i) => {
    const value = start + i;
    return { code: value, name: value };
  });
};

export const getWeekDaysInfo = () => {
  moment.locale("it"); // Imposta la localizzazione in italiano
  const days = [];
  const today = moment(); // Oggi
  const startOfWeek = moment().startOf("week").isoWeekday(1);

  for (let i = 0; i < 7; i++) {
    const day = startOfWeek.clone().add(i, "days");
    days.push({
      dayOfWeek: day.format("dddd"), // Nome del giorno
      dayNumber: day.format("D"), // Numero del giorno nel mese
      month: day.format("MMMM"), // Nome del mese in lettere
      year: day.format("YYYY"), // Anno in numero
      isToday: day.isSame(today, "day"), // True se è oggi
      collections: 5,
    });
  }

  return days;
};

//----------------------------------------------

export const modalDefault = {
  open: false,
  size: "md",
};
