import {
  faArrowDownShortWide,
  faArrowUpShortWide,
  faPlus,
  faTrash,
} from "@awesome.me/kit-c1b85ff10f/icons/classic/light";
import { useAppContext } from "../../../AppProvider";
import { dictionary } from "../../../utilities/dictionary";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useIndexWarehouseArticlesContext } from "../providers/Index";

export const isDisabledDelete = (selectedRows) => !selectedRows.length > 0;

export const useListProps = (action, shippingRequired) => {
  const appContext = useAppContext();
  const language = appContext?.language;

  const context = useIndexWarehouseArticlesContext();

  return {
    path: "/logistic-service/warehouse-article",
    create: {
      enabled: action != "details",
      action: () => {
        context?.setModal({
          open: true,
          title: `${dictionary["add"][language]} ${dictionary["product"][
            language
          ].toLowerCase()}`,
          action: "create",
        });
      },
      title: `${dictionary["add"][language]} ${dictionary["product"][
        language
      ].toLowerCase()}`,
      icon: faPlus,
    },
    selectRowsEnabled: { enabled: action !== "details" },
    actionBar: { enabled: action !== "details", fixed: action !== "details" },
    sorting: [
      {
        orderBy: "desc",
        column: "article.name",
        icon: faArrowDownShortWide,
        label: dictionary["name"][language],
        isDefault: true,
      },
      {
        orderBy: "asc",
        column: "article.name",
        icon: faArrowUpShortWide,
        label: dictionary["name"][language],
        isDefault: false,
      },
    ],
    banner: {
      enabled: false,
    },
    title: shippingRequired ? (
      <h3 className="m-0">{dictionary["products"][language]}</h3>
    ) : (
      <h3 className="m-0">
        {dictionary["products"][language]} {dictionary["digitals"][language]}
      </h3>
    ),
    omnisearch: {
      enabled: false,
      placeholder: dictionary["references"][language],
    },
    defaultDate: {
      enabled: false,
    },
    massiveActions: [
      {
        onClick: (selectedRows) =>
          context.setModal({
            open: true,
            title: dictionary["confirm_deletion"][language],
            action: "delete",
            data: selectedRows.map((item) => item.id),
          }),

        isDisabled: isDisabledDelete,
        label: dictionary["delete_selected"][language],
        icon: <FontAwesomeIcon icon={faTrash} />,
        className: "button-1 button-1-danger",
      },
    ],
    filters: {
      list: [
        {
          fieldName: "article.shippingRequired",
          value: [
            {
              value: shippingRequired ? "true" : "false",
              isBool: true,
            },
          ],
          type: "select",
          hidden: true,
        },
      ],
      preserve: false,
      name: "",
    },
  };
};
