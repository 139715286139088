import React, { createContext, useContext, useEffect, useState } from "react";

import { OrdersService } from "../../../services/logistic/orders";
import { callErrorToast } from "../../../utilities";

const DeleteOrderContext = createContext();

const ordersService = new OrdersService();

const DeleteOrdersProvider = ({ children, data, callback }) => {
  const [orders, setOrders] = useState(data);
  const [ordersError, setOrdersError] = useState(null);
  const [ordersLoader, setOrdersLoader] = useState(false);

  const deleteOrders = () => {
    setOrdersLoader(true);
    const promises = orders.map((elem) => {
      if (elem.lastStatus === "DRAFT") {
        return ordersService.delete(elem.id);
      }
      return ordersService.cancel(elem.id);
    });
    Promise.all(promises)
      .then((response) => {
        setOrdersLoader(false);
        if (callback) {
          callback();
        }
      })
      .catch((err) => {
        setOrdersError(err);
      });
  };

  useEffect(() => {
    if (ordersError) {
      callErrorToast(ordersError);
    }
  }, [ordersError]);

  return (
    <DeleteOrderContext.Provider
      value={{
        orders,
        ordersError,
        ordersLoader,
        deleteOrders,

        callback,
      }}
    >
      {children}
    </DeleteOrderContext.Provider>
  );
};

const useDeleteOrderContext = () => {
  return useContext(DeleteOrderContext);
};

export { DeleteOrdersProvider, useDeleteOrderContext };
