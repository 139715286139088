import { useAppContext } from "../../../AppProvider";
import { dictionary } from "../../../utilities/dictionary";

export const useListProps = () => {
  const appContext = useAppContext();
  const language = appContext.language;
  return {
    path: "/logistic-service/order-details",
    title: <h3>{dictionary["products"][language]}</h3>,
    pagination: { enabled: false, page: 0, size: 200 },
    selectRowsEnabled: { enabled: false },
    actionBar: { enabled: false },
    sorting: [
      {
        orderBy: "desc",
        column: "article.shippingRequired",
        isDefault: true,
      },
    ],
  };
};
