import React, { createContext, useState } from "react";

import { OrdersService } from "../../../services/logistic/orders";

export const ProcessOrderContext = createContext();

const ordersService = new OrdersService();

export const ProcessOrderProvider = ({ children, data, unmountCallback }) => {
  const [orders, setOrders] = useState(data);
  const [ordersError, setOrdersError] = useState(null);
  const [ordersLoader, setOrdersLoader] = useState(false);
  const processOrders = () => {
    setOrdersLoader(true);
    const receivePromises = orders.map((elem) => {
      return ordersService.receive(elem);
    });
    Promise.all(receivePromises)
      .then(() => {
        const processPromises = orders.map((elem) => {
          return ordersService.process(elem);
        });

        Promise.all(processPromises)
          .then(() => {
            setOrdersLoader(false);
            unmountCallback();
          })
          .catch((err) => {
            setOrdersError(err);
          });
      })
      .catch((err) => {
        setOrdersError(err);
      });
  };

  return (
    <ProcessOrderContext.Provider
      value={{
        //#region Order
        orders,
        ordersError,
        ordersLoader,
        processOrders,
        unmountCallback,
        //#endregion
      }}
    >
      {children}
    </ProcessOrderContext.Provider>
  );
};
