import React, { useEffect } from "react";
import { Card, CardBody } from "reactstrap";
import BreadcrumbItem from "../../Components/common/Breadcrumb";
import { useNavigate, useParams } from "react-router-dom";
import InputBox from "../../Components/common/InputBox";
import { getAllStoreIntegrations } from "../../utilities/asyncSelectCallAPI";
import {
  getSourceIntegrationOptionStyle,
  getStoreIntegrationOptionStyle,
} from "../../Components/common/AsyncSelect/utilities";
import AsyncSelect from "../../Components/common/AsyncSelect/AsyncSelect";
import { Loader } from "../../Components/common/Loader";
import { useArticleContext } from "./providers/CreateEdit";
import { IndexArticleImagesProvider } from "../ArticleImages/providers/Index";
import ArticleImages from "../ArticleImages";
import { IndexArticleVariantsProvider } from "../ArticleVariants/providers/Index";
import ArticleVariants from "../ArticleVariants";

export const CreateEdit = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const context = useArticleContext();

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    const article = {
      ...context.article,
      [name]: value,
    };

    context.editArticle(article);
    context.removeError(name);
  };

  const handleAsyncSelectChange = (selector, value) => {
    const article = {
      ...context.article,
      [selector]: value,
    };

    context.editArticle(article);
  };

  const handleCheckboxChange = (event) => {
    const { name, value } = event.target;

    const article = {
      ...context.article,
      [name]: !context.article[name],
    };

    context.editArticle(article);
  };

  useEffect(() => {
    if (!id) {
      if (context.autosave) {
        context.createArticle();
      }
    } else {
      context.getArticle();
    }
  }, []);

  return (
    <React.Fragment>
      <BreadcrumbItem
        title="Prodotti"
        breadcrumbItem={id ? "Modifica prodotto" : "Nuovo prodotto"}
        link={`/articles`}
      />

      <div className="content" id="container">
        <div className="row">
          <div className="col">
            <Card>
              {context.articleLoader && <Loader show={context.articleLoader} />}
              <CardBody>
                <h5>Informazioni generali</h5>
                <div className="row">
                  <div className="col">
                    <label className="form-label">Configurazione store:</label>
                    <AsyncSelect
                      name="sourceIntegration"
                      optionValue="id"
                      optionLabel="name"
                      value={context?.article?.sourceIntegration}
                      onSearch={handleAsyncSelectChange}
                      loadOptions={(inputValue, pagination) => {
                        return getAllStoreIntegrations(inputValue, pagination, {
                          orderBy: "asc",
                          selector: "name",
                        });
                      }}
                      optionStyle={getSourceIntegrationOptionStyle}
                    />
                  </div>
                  <div className="col">
                    <label className="form-label">Nome:</label>
                    <InputBox
                      name="name"
                      value={context.article?.name || ""}
                      placeholder={"Penna a sfera"}
                      type="text"
                      onChange={handleInputChange}
                      min={0}
                      max={200}
                      error={context.articleError?.response?.data?.name}
                    />
                  </div>
                  <div className="col">
                    <label className="form-label">Rif. Esterno:</label>
                    <InputBox
                      name="externalReference"
                      value={context.article?.externalReference || ""}
                      placeholder={"EXTREF123"}
                      type="text"
                      onChange={handleInputChange}
                      min={0}
                      max={50}
                      error={
                        context.articleError?.response?.data?.externalReference
                      }
                    />
                  </div>
                </div>
                <h5 className="mt-3">Informazioni aggiuntive</h5>
                <div className="row">
                  <div className="col">
                    <label className="form-label">Larghezza (cm):</label>
                    <InputBox
                      name="width"
                      value={context.article?.width || ""}
                      placeholder={"10"}
                      type="positive-number"
                      onChange={handleInputChange}
                      min={0}
                      max={10000}
                      error={context.articleError?.response?.data?.width}
                    />
                  </div>
                  <div className="col">
                    <label className="form-label">Altezza (cm):</label>
                    <InputBox
                      name="height"
                      value={context.article?.height || ""}
                      placeholder={"10"}
                      type="positive-number"
                      onChange={handleInputChange}
                      min={0}
                      max={10000}
                      error={context.articleError?.response?.data?.height}
                    />
                  </div>
                  <div className="col">
                    <label className="form-label">Lunghezza (cm):</label>
                    <InputBox
                      name="length"
                      value={context.article?.length || ""}
                      placeholder={"10"}
                      type="positive-number"
                      onChange={handleInputChange}
                      min={0}
                      max={10000}
                      error={context.articleError?.response?.data?.length}
                    />
                  </div>
                  <div className="col">
                    <label className="form-label">Peso (kg):</label>
                    <InputBox
                      name="weight"
                      value={context.article?.weight || ""}
                      placeholder={"10"}
                      type="positive-number"
                      onChange={handleInputChange}
                      min={0}
                      max={1000}
                      precision={3}
                      error={context.articleError?.response?.data?.weight}
                    />
                  </div>
                  <div className="col">
                    <label className="form-label">Venditore:</label>
                    <InputBox
                      name="vendor"
                      value={context.article?.vendor || ""}
                      type="text"
                      onChange={handleInputChange}
                      min={0}
                      max={50}
                      error={context.articleError?.response?.data?.vendor}
                    />
                  </div>
                  <div className="col">
                    <label className="form-label">Tipologia:</label>
                    <InputBox
                      name="type"
                      value={context.article?.type || ""}
                      type="text"
                      onChange={handleInputChange}
                      min={0}
                      max={50}
                      error={context.articleError?.response?.data?.type}
                    />
                  </div>
                </div>
                <div className="row mt-3 align-items-end">
                  <div className="col-2">
                    <label className="form-label">Prezzo:</label>
                    <InputBox
                      name="price"
                      value={context.article?.price || ""}
                      placeholder={"10"}
                      type="number"
                      onChange={handleInputChange}
                      min={0}
                      max={10000}
                      error={context.articleError?.response?.data?.price}
                    />
                  </div>
                  <div className="col-2">
                    <label className="form-label">Prezzo originale:</label>
                    <InputBox
                      name="originalPrice"
                      value={context.article?.originalPrice || ""}
                      placeholder={"10"}
                      type="number"
                      onChange={handleInputChange}
                      min={0}
                      max={10000}
                      error={
                        context.articleError?.response?.data?.originalPrice
                      }
                    />
                  </div>
                  <div className="col-2">
                    <label className="form-check mb-2">
                      <input
                        name="pub"
                        type="checkbox"
                        className="form-check-input form-check-input-success"
                        value={context.article?.pub || false}
                        checked={context.article?.pub || false}
                        onChange={handleCheckboxChange}
                      />
                      <span className="form-check-label">Pubblico</span>
                    </label>
                  </div>
                  <div className="col-2">
                    <label className="form-check mb-2">
                      <input
                        name="shippingRequired"
                        type="checkbox"
                        className="form-check-input form-check-input-success"
                        value={context.article?.shippingRequired || false}
                        checked={context.article?.shippingRequired || false}
                        onChange={handleCheckboxChange}
                      />
                      <span className="form-check-label">
                        Spedizione necessaria
                      </span>
                    </label>
                  </div>
                </div>
              </CardBody>
            </Card>
          </div>
        </div>
        <div className="row">
          <div className="col">
            {/* <IndexArticleImagesProvider autosave={true}>
              <ArticleImages />
            </IndexArticleImagesProvider> */}
          </div>
        </div>
        <div className="row">
          <div className="col">
            {/* <IndexArticleVariantsProvider autosave={true}>
              <ArticleVariants />
            </IndexArticleVariantsProvider> */}
            {/* <Card>
              {articleVariantsLoader ? (
                <Loader show={articleVariantsLoader} />
              ) : (
                ""
              )}
              <CardBody>
                <div className="row align-items-center mb-3">
                  <div className="col">
                    <h5 className="m-0">Varianti</h5>
                  </div>
                  <div className="col text-end">
                    <Button
                      color="light"
                      type="button"
                      className="btn btn-outline-success btn-sm"
                      onClick={createArticleVariant}
                    >
                      Aggiungi variante <i className="ph-plus ms-2"></i>
                    </Button>
                  </div>
                </div>
                {articleVariants.map((variant, key) => {
                  return (
                    <div className="row" key={key}>
                      <div className="col">
                        <Card>
                          <CardBody>
                            <div className="row">
                              <div className="col">
                                <h5>Informazioni generali</h5>
                              </div>
                              <div className="col text-end ">
                                <button
                                  title="Duplica"
                                  type="button"
                                  className="btn btn-outline-light p-1 me-2"
                                  onClick={() => {
                                    const articleVariantNormalized =
                                      articleVariantNormalizer({ ...variant });
                                    cloneArticleVariant(
                                      articleVariantNormalized
                                    );
                                  }}
                                >
                                  <FontAwesomeIcon
                                    icon={faClone}
                                    className="m-1"
                                    size="1x"
                                  />
                                </button>
                                <button
                                  title="Elimina"
                                  type="button"
                                  className="btn btn-outline-danger p-1"
                                  onClick={() =>
                                    deleteArticleVariant(variant.id)
                                  }
                                >
                                  <i className="ph-trash m-1"></i>
                                </button>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col">
                                <label className="form-label">Nome:</label>
                                <InputBox
                                  name="name"
                                  value={variant.name || ""}
                                  placeholder={"Penna a sfera rossa"}
                                  type="text"
                                  onChange={handleArticleVariantInputChange}
                                  dataId={variant.id}
                                  min={0}
                                  max={50}
                                />
                              </div>
                              <div className="col">
                                <label className="form-label">Barcode:</label>
                                <InputBox
                                  name="barcode"
                                  value={variant.barcode || ""}
                                  placeholder={"0799439112766"}
                                  type="text"
                                  onChange={handleArticleVariantInputChange}
                                  dataId={variant.id}
                                  min={0}
                                  max={50}
                                />
                              </div>
                              <div className="col">
                                <label className="form-label">
                                  Tipologia barcode:
                                </label>
                                <Select
                                  options={barcodeTypes}
                                  getOptionValue={(option) => option.code}
                                  getOptionLabel={(option) => option.name}
                                  onChange={(option, element) => {
                                    handleArticleVariantSelectChange(
                                      option,
                                      element,
                                      variant.id
                                    );
                                  }}
                                  name="barcodeType"
                                  value={variant.barcodeType || null}
                                />
                              </div>
                              <div className="col">
                                <label className="form-label">SKU:</label>
                                <InputBox
                                  name="sku"
                                  value={variant.sku || ""}
                                  placeholder={""}
                                  type="text"
                                  onChange={handleArticleVariantInputChange}
                                  dataId={variant.id}
                                  min={0}
                                  max={50}
                                />
                              </div>
                              <div className="col">
                                <label className="form-label">
                                  Prezzo corrente:
                                </label>
                                <InputBox
                                  name="price"
                                  value={variant.price || ""}
                                  placeholder={"19.99"}
                                  type="positive-number"
                                  onChange={handleArticleVariantInputChange}
                                  dataId={variant.id}
                                  min={0}
                                  max={10000}
                                />
                              </div>
                              <div className="col">
                                <label className="form-label">Peso:</label>
                                <InputBox
                                  name="weight"
                                  value={variant.weight || ""}
                                  placeholder={"1"}
                                  type="positive-number"
                                  onChange={handleArticleVariantInputChange}
                                  dataId={variant.id}
                                  min={0}
                                  max={10000}
                                />
                              </div>
                            </div>
                            <div className="row mt-2 align-items-center">
                              <div className="col">
                                <label className="form-label">ASIN:</label>
                                <InputBox
                                  name="asin"
                                  value={variant.asin || ""}
                                  placeholder={""}
                                  type="text"
                                  onChange={handleArticleVariantInputChange}
                                  dataId={variant.id}
                                  min={0}
                                  max={50}
                                />
                              </div>
                              <div className="col">
                                <label className="form-label">
                                  Prezzo originale:
                                </label>
                                <InputBox
                                  name="originalPrice"
                                  value={variant.originalPrice || ""}
                                  placeholder={"19.99"}
                                  type="positive-number"
                                  onChange={handleArticleVariantInputChange}
                                  dataId={variant.id}
                                  min={0}
                                  max={10000}
                                />
                              </div>
                              <div className="col">
                                <label className="form-label">
                                  Unità di peso:
                                </label>
                                <InputBox
                                  name="weightUnit"
                                  value={variant.weightUnit || ""}
                                  placeholder={"KG"}
                                  onChange={handleArticleVariantInputChange}
                                  dataId={variant.id}
                                  min={0}
                                  max={50}
                                />
                              </div>
                              <div className="col">
                                <label className="form-label">Tax code:</label>
                                <InputBox
                                  name="taxCode"
                                  value={variant.taxCode || ""}
                                  placeholder={"1"}
                                  type="text"
                                  onChange={handleArticleVariantInputChange}
                                  dataId={variant.id}
                                  min={0}
                                  max={50}
                                />
                              </div>
                              <div className="col">
                                <label className="form-label">
                                  Paese d'origine:
                                </label>
                                <Select
                                  options={countryIsoCodes2}
                                  getOptionValue={(option) => option.code}
                                  getOptionLabel={(option) => option.name}
                                  onChange={(option, element) => {
                                    handleArticleVariantSelectChange(
                                      option,
                                      element,
                                      variant.id
                                    );
                                  }}
                                  name="originCountryCode"
                                  value={variant.originCountryCode || null}
                                  maxMenuHeight={100}
                                />
                              </div>
                              <div className="col">
                                <label className="form-label">Codice HS:</label>
                                <InputBox
                                  name="hs"
                                  value={variant.hs || ""}
                                  placeholder={"62.04.62.1100"}
                                  type="text"
                                  onChange={handleArticleVariantInputChange}
                                  dataId={variant.id}
                                  min={0}
                                  max={50}
                                />
                              </div>
                              <div className="col">
                                <label className="form-check mb-2">
                                  <input
                                    type="checkbox"
                                    name="shippingRequired"
                                    className="form-check-input form-check-input-success"
                                    value={variant.shippingRequired}
                                    checked={variant.shippingRequired}
                                    onChange={() => {
                                      const newVariant = {
                                        ...articleVariants.find(
                                          (elem) => elem.id === variant.id
                                        ),
                                      };
                                      newVariant.shippingRequired =
                                        !newVariant.shippingRequired;

                                      const articleVariantNormalized =
                                        articleVariantNormalizer(newVariant);
                                      editArticleVariant(
                                        articleVariantNormalized
                                      );
                                    }}
                                  />
                                  <span className="form-check-label">
                                    Spedizione necessaria
                                  </span>
                                </label>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </div>
                    </div>
                  );
                })}
              </CardBody>
            </Card> */}
          </div>
        </div>

        <div className="row">
          <div className="col text-start">
            <button
              type="button"
              className="btn btn-light btn-sm"
              onClick={() => {
                navigate(`/articles`);
              }}
            >
              <i className="ph-arrow-left ph-sm me-2"></i>Torna all'elenco
              prodotti
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
