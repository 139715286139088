import React, { useState, useEffect } from "react";
import { Loader } from "./Loader";
import { toast } from "react-toastify";

const ZPLViewer = ({ url }) => {
  const [labelImage, setLabelImage] = useState("");

  const generateLabel = async (zplCode) => {
    try {
      const response = await fetch(
        `https://api.labelary.com/v1/printers/8dpmm/labels/4x6/`,
        {
          method: "POST",
          body: zplCode,
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            accept: "application/pdf",
          },
        }
      );
      if (!response.ok) {
        toast.error(`Errore durante la conversione del file zpl`, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
      const blob = await response.blob();
      const reader = new FileReader();
      reader.onload = () => {
        const base64String = reader.result.split(",")[1];
        setLabelImage(base64String);
      };
      reader.readAsDataURL(blob);
    } catch (error) {
      toast.error(`Errore durante la conversione del file zpl`, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(url);
        if (!response.ok) {
          toast.error(`Errore durante il recupero del file`, {
            position: toast.POSITION.TOP_CENTER,
          });
        }
        const content = await response.text();
        generateLabel(content);
      } catch (error) {
        toast.error(`Errore durante il recupero del file`, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    };
    if (url) {
      fetchData();
    }
  }, [url]);

  return (
    <div>
      {labelImage ? (
        <object
          style={{ width: "100%", height: "500px" }}
          type="application/pdf"
          data={`data:application/pdf;base64,${labelImage}`}
        ></object>
      ) : (
        <Loader show={true} />
      )}
    </div>
  );
};

export default ZPLViewer;
