import React, { createContext, useContext, useEffect, useState } from "react";

import { OrderDetailsService } from "../../../services/logistic/orderDetails";
import { callErrorToast, modalDefault } from "../../../utilities";
import { OrdersService } from "../../../services/logistic/orders";

const IndexOrderDetailsContext = createContext();

const ordersService = new OrdersService();

const IndexOrderDetailsProvider = ({ children, parentId, callback }) => {
  const [orderDetail, setOrderDetail] = useState();
  const [orderDetailError, setOrderDetailError] = useState(null);
  const [orderDetailLoader, setOrderDetailLoader] = useState(false);

  const [modal, setModal] = useState({
    ...modalDefault,
  });

  const nextDetail = () => {
    ordersService
      .nextDetail(parentId)
      .then((res) => {
        if (res.status === 200) {
          setModal({
            ...modal,
            title: "Aggiunta barcode",
            icon: "ph-pencil",
            open: true,
            action: "addBarcode",
            id: res.data,
          });
        }
      })
      .catch((err) => setOrderDetailError(err));
  };

  useEffect(() => {
    if (orderDetailError) {
      callErrorToast(orderDetailError);
    }
  }, [orderDetailError]);

  return (
    <IndexOrderDetailsContext.Provider
      value={{
        orderDetail,
        orderDetailError,
        orderDetailLoader,
        nextDetail,

        callback,

        modal,
        setModal,
      }}
    >
      {children}
    </IndexOrderDetailsContext.Provider>
  );
};

const useIndexOrderDetailsContext = () => {
  return useContext(IndexOrderDetailsContext);
};

export { IndexOrderDetailsProvider, useIndexOrderDetailsContext };
