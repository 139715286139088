// import React, { useEffect } from "react";
// import { Button } from "reactstrap";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faLoader } from "@awesome.me/kit-c1b85ff10f/icons/classic/light";
// import { useExportForActivationOrderContext } from "./providers/ExportForActivation";

// const ExportForActivation = () => {
//   const exportForActivationOrderContext = useExportForActivationOrderContext();

//   return (
//     <form>
//       <div className="row mb-3">
//         <div className="col">
//           <label>
//             {exportForActivationOrderContext.orders?.length > 1
//               ? "Sei sicuro di voler esportare gli ordini selezionati?"
//               : "Sei sicuro di voler esportare il seguente ordine?"}
//           </label>
//         </div>
//       </div>
//       <div className="d-flex justify-content-end">
//         <button
//           className="btn btn-link"
//           onClick={() => {
//             if (exportForActivationOrderContext.unmountCallback) {
//               exportForActivationOrderContext.unmountCallback();
//             }
//           }}
//         >
//           Annulla
//         </button>
//         <Button
//           className="btn ms-3"
//           onClick={exportForActivationOrderContext.exportOrders}
//           disabled={exportForActivationOrderContext.ordersLoader}
//         >
//           {exportForActivationOrderContext.ordersLoader ? (
//             <FontAwesomeIcon icon={faLoader} spin />
//           ) : (
//             "Esporta"
//           )}
//         </Button>
//       </div>
//     </form>
//   );
// };

// export default ExportForActivation;

import React from "react";
import { useAppContext } from "../../AppProvider";
import { dictionary } from "../../utilities/dictionary";
import { ButtonUtility } from "../../Components/common/Buttons/ButtonUtility";
import { useExportForActivationOrderContext } from "./providers/ExportForActivation";
import { ButtonConfirm } from "../../Components/common/Buttons/ButtonConfirm";

const ExportForActivation = () => {
  const appContext = useAppContext();
  const language = appContext.language;

  const context = useExportForActivationOrderContext();

  return (
    <React.Fragment>
      <div className="row mb-3">
        <div className="col">
          <label className="label-4 text-center">
            {dictionary["confirm_export"][language]}
          </label>
        </div>
      </div>
      <div className="row mb-3">
        <div className="col">
          <label className="label-5 text-center">
            {context.orders?.length > 1
              ? `${dictionary["ask_confirm_export"][language]} ${
                  context.orders?.length
                } ${dictionary["items"][language].toLowerCase()}?`
              : `${dictionary["ask_confirm_export"][language]}?`}
          </label>
        </div>
      </div>
      <div className="d-flex">
        <ButtonUtility
          className="w-50 me-2"
          onClick={() => {
            if (context.callback) {
              context.callback();
            }
          }}
        >
          {dictionary["cancel"][language]}
        </ButtonUtility>
        <ButtonConfirm
          className="w-50"
          onClick={() => context.exportOrders()}
          disabled={context.ordersLoader}
          loading={context.ordersLoader}
        >
          {dictionary["export"][language]}
        </ButtonConfirm>
      </div>
    </React.Fragment>
  );
};

export default ExportForActivation;
