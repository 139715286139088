import React, { createContext, useState, useContext } from "react";
import { modalDefault } from "../../../utilities";

export const IndexWarehouseArticlesContext = createContext();

const IndexWarehouseArticlesProvider = ({ children, parentId }) => {
  const [modal, setModal] = useState({
    ...modalDefault,
  });

  return (
    <IndexWarehouseArticlesContext.Provider
      value={{
        parentId,

        modal,
        setModal,
      }}
    >
      {children}
    </IndexWarehouseArticlesContext.Provider>
  );
};

const useIndexWarehouseArticlesContext = () => {
  return useContext(IndexWarehouseArticlesContext);
};

export { IndexWarehouseArticlesProvider, useIndexWarehouseArticlesContext };
