import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { dictionary } from "./dictionary";
import {
  faBoxTaped,
  faEnvelope,
  faFile,
  faPalletBox,
} from "@awesome.me/kit-c1b85ff10f/icons/classic/light";
import { useTypes } from "./types";

const lang = navigator.language || navigator.userLanguage;

export const getParcelTypeBadge = (code, size = "2x") => {
  switch (code) {
    case "PKG":
      return (
        <FontAwesomeIcon
          title={dictionary["package"][lang]}
          icon={faBoxTaped}
          size={size}
        />
      );
    case "ENV":
      return (
        <FontAwesomeIcon
          icon={faEnvelope}
          size={size}
          title={dictionary["envelope"][lang]}
        />
      );
    case "DOC":
      return (
        <FontAwesomeIcon
          icon={faFile}
          size={size}
          title={dictionary["document"][lang]}
        />
      );
    case "PAL":
      return (
        <FontAwesomeIcon
          icon={faPalletBox}
          size={size}
          title={dictionary["pallet"][lang]}
        />
      );
  }
};

export const getShipmentStatusBadge = (code) => {
  const badge = <span></span>;
};

export const getOrderStatusBadge = (code) => {
  const types = useTypes();
  const status = types.orderStatus.find((elem) => elem.value === code);
  return (
    <span
      style={{ backgroundColor: status?.color }}
      className="badge"
      title={status?.label}
    >
      {status?.label}
    </span>
  );
};

export const getLocationLevelBadge = (level) => {
  return (
    <span
      style={{ backgroundColor: level?.color }}
      className="badge"
      title={level?.label}
    >
      {level?.label}
    </span>
  );
};
