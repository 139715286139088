import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useMemo,
} from "react";
import {
  buildQuerySort,
  callErrorToast,
  buildQuerySearch,
  getUniqueSelectors,
} from "../../../../utilities/index";
import useFetch from "../../../../customHooks/useFetch";
import { useDefaultValues } from "../utilities";

export const ListContext = createContext();

/**
 * ListProvider - Fornisce il contesto per gestire lo stato e i dati della lista.
 *
 * @param {string} path - Il percorso dell'endpoint API per recuperare i dati.
 * @param {Array} sorting - Un array di oggetti che definiscono i criteri di ordinamento.
 * @param {Array} filters - Un array di oggetti che definiscono i filtri per la lista.
 *
 * @example
 * <ListProvider
 *   path="/shipment-service/shipments"
 *   sorting={[
 *     {
 *       orderBy: "desc",
 *       column: "creationDate",
 *       label: "Data di Creazione",
 *       isDefault: true,
 *     },
 *   ]}
 *   filters={[
 *     {
 *       fieldName: "status.code",
 *       label: "Stato",
 *       type: "async-select", //text or select or datepicker
 *       loadOptions: (searchValue) => getAllStatusCodes(searchValue),
 *       isMulti: true,
 *       optionStyle: getShipmentStatusOptionStyle,
 *       primary: true,
 *     },
 *   ]}
 * >
 *  <List ... />
 * </ListProvider>
 */
const ListProvider = ({
  children,
  path,
  sorting = [],
  filters = { list: [], preserve: true, name: "" },
  defaultDate,
  parentId,
  defaultPagination = { page: 0, size: 10 },
}) => {
  const { defaultParams, defaultFilters, cleanDefaults } = useDefaultValues(
    filters,
    defaultDate
  );
  const [pagination, setPagination] = useState({ ...defaultPagination });
  const [sort, setSort] = useState(
    sorting.find((x) => x.isDefault) ? [sorting.find((x) => x.isDefault)] : []
  );
  const [params, setParams] = useState(defaultParams);
  const [freeSearch, setFreeSearch] = useState("");
  const [query, setQuery] = useState();

  const [selectRowsEnabled, setSelectRowsEnabled] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);

  const { data, loading, error, refreshData } = useFetch(
    `${path}?page=${pagination.page}&size=${pagination.size}${
      sort?.length > 0 ? buildQuerySort(sort) : ""
    }${params?.length > 0 ? `&search=${buildQuerySearch(params)}` : ""}${
      freeSearch ? `&freeSearch=${freeSearch}` : ""
    }${parentId ? `&parentId=${parentId}` : ""}`
  );

  const allRowsSelected =
    data?.content?.length > 0 && selectedRows.length === data?.content?.length;

  const handleSelectRow = (item) => {
    setSelectedRows((prevSelectedRows) => {
      // Verifica se l'oggetto è già selezionato usando l'ID come riferimento
      const isAlreadySelected = prevSelectedRows.some(
        (selectedItem) => selectedItem.id === item.id
      );

      if (isAlreadySelected) {
        // Se è già selezionato, rimuovilo dall'array
        return prevSelectedRows.filter(
          (selectedItem) => selectedItem.id !== item.id
        );
      } else {
        // Se non è selezionato, aggiungilo all'array
        return [...prevSelectedRows, item];
      }
    });
  };

  // Funzione per selezionare o deselezionare tutte le righe
  const handleSelectAllRows = () => {
    if (allRowsSelected) {
      setSelectedRows([]); // Deseleziona tutte
    } else {
      setSelectedRows(data.content); // Seleziona tutte le righe
    }
  };

  const handlePagination = (pagination) => {
    setPagination((prevPagination) => {
      return { ...prevPagination, ...pagination };
    });
  };

  const onClear = () => {
    let params = [];
    //Pulisco tutti i filtri eccetto la defaultDate se presente
    if (defaultDate?.enabled) {
      params = getUniqueSelectors(
        defaultDate.fieldName,
        defaultDate.value,
        true,
        params
      );
    }
    setParams(params);
    cleanDefaults();

    if (filters.name) {
      localStorage.removeItem(filters.name + "_filters");
    }
  };

  const onSearch = (selector, value, preciseSearch = false) => {
    setParams((params) => {
      return getUniqueSelectors(selector, value, preciseSearch, params);
    });
    setPagination((prevPagination) => {
      return { ...prevPagination, page: 0 };
    });
  };

  const onForceSearch = (forcedFilters) => {
    let forcedParams = [];
    for (let f in forcedFilters) {
      forcedParams = getUniqueSelectors(
        f,
        forcedFilters[f],
        false,
        forcedParams
      );
    }

    setParams(forcedParams);
    setPagination((prevPagination) => {
      return { ...prevPagination, page: 0 };
    });
  };

  const onFreeSearch = (value) => {
    setFreeSearch(value);
    setPagination((prevPagination) => {
      return { ...prevPagination, page: 0 };
    });
  };

  useEffect(() => {
    setQuery(buildQuerySearch(params));
  }, [pagination, sort, params, query]);

  useEffect(() => {
    if (!selectRowsEnabled) {
      setSelectedRows([]);
    }
  }, [selectRowsEnabled]);

  useEffect(() => {
    if (error) {
      callErrorToast(error);
    }
  }, [error]);

  return (
    <ListContext.Provider
      value={{
        data,
        loading,
        error,
        refreshData,

        onSearch,
        onForceSearch,
        onFreeSearch,
        onClear,

        pagination,
        setPagination,
        handlePagination,
        sorting,
        sort,
        setSort,
        params,
        setParams,
        query,
        setQuery,
        defaultFilters,
        filters,
        defaultDate,

        selectRowsEnabled,
        setSelectRowsEnabled,
        allRowsSelected,
        selectedRows,
        handleSelectRow,
        handleSelectAllRows,
      }}
    >
      {children}
    </ListContext.Provider>
  );
};

const useListContext = () => {
  return useContext(ListContext);
};

export { ListProvider, useListContext };
