import React, { useEffect } from "react";
import { useOidcAccessToken } from "@axa-fr/react-oidc";
import { useParams } from "react-router-dom";
import BreadcrumbItem from "../../Components/common/Breadcrumb";
import { useWarehouseContext } from "./providers/CreateEdit";
import { Card, CardBody } from "reactstrap";
import { Loader } from "../../Components/common/Loader";
import AsyncSelect from "../../Components/common/AsyncSelect/AsyncSelect";
import { getAllLogisticIntegrations } from "../../utilities/asyncSelectCallAPI";
import { getLogisticIntegrationOptionStyle } from "../../Components/common/AsyncSelect/utilities";
import InputBox from "../../Components/common/InputBox";
import { warehouseNormalizer } from "./utilities";
import { callErrorToast, orderBykey } from "../../utilities";
import Location from "../Locations/Location";
import WarehouseArticles from "../WarehouseArticles/index";
import { IndexWarehouseArticlesProvider } from "../WarehouseArticles/providers/Index";

export const CreateEdit = () => {
  const { id } = useParams();
  const action = window.location.pathname.split("/")[2];
  const { accessTokenPayload } = useOidcAccessToken();

  const warehouseContext = useWarehouseContext();

  const handleWarehouseInputChange = (event) => {
    const { name, value } = event.target;
    const warehouseNormalized = warehouseNormalizer(
      { ...warehouseContext?.warehouse, [name]: value },
      accessTokenPayload
    );
    warehouseContext?.editWarehouse(warehouseNormalized);
  };

  const handleLogisticIntegrationAsyncSelectChange = (selector, value) => {
    let newWarehouse = { ...warehouseContext.warehouse };
    delete newWarehouse.logisticIntegration;
    delete newWarehouse.logisticIntegrationId;
    if (value) {
      newWarehouse.logisticIntegration = value;
    }
    warehouseContext?.setLogisticIntegration(value);
    warehouseContext?.editWarehouse(
      warehouseNormalizer(newWarehouse, accessTokenPayload)
    );
  };

  useEffect(() => {
    if (warehouseContext?.warehouse?.logisticIntegration) {
      warehouseContext?.getLogisticIntegration(
        warehouseContext.warehouse?.logisticIntegration.id
      );
    }
  }, [warehouseContext?.warehouse?.logisticIntegration]);

  useEffect(() => {
    if (warehouseContext?.logisticIntegrationsError) {
      callErrorToast(warehouseContext.logisticIntegrationsError);
    }
  }, [warehouseContext?.logisticIntegrationsError]);

  useEffect(() => {
    if (warehouseContext?.logisticIntegrationError) {
      callErrorToast(warehouseContext.logisticIntegrationError);
    }
  }, [warehouseContext?.logisticIntegrationError]);

  useEffect(() => {
    if (!id) {
      warehouseContext.createWarehouse();
    } else {
      warehouseContext.getWarehouse();
    }
  }, []);

  return (
    <React.Fragment>
      <BreadcrumbItem
        title="Magazzini"
        breadcrumbItem={id ? "Modifica magazzino" : "Nuovo magazzino"}
        link={`/warehouses`}
      />
      <div className="content" id="container">
        <div className="row">
          <div className="col">
            <Card>
              {warehouseContext?.warehouseLoader ? (
                <Loader show={warehouseContext.warehouseLoader} />
              ) : (
                ""
              )}
              <CardBody>
                <h5>Informazioni generali</h5>
                <div className="row">
                  <div className="col-3">
                    <label className="form-label">
                      Configurazione logistica:
                    </label>
                    <AsyncSelect
                      name="logisticIntegration"
                      optionValue="id"
                      optionLabel="name"
                      value={warehouseContext?.logisticIntegration}
                      isClearable={false}
                      onSearch={handleLogisticIntegrationAsyncSelectChange}
                      loadOptions={(inputValue, pagination) => {
                        return getAllLogisticIntegrations(
                          inputValue,
                          pagination,
                          {
                            orderBy: "asc",
                            selector: "name",
                          }
                        );
                      }}
                      optionStyle={getLogisticIntegrationOptionStyle}
                    />
                  </div>
                  <div className="col-3">
                    <label className="form-label">Nome:</label>
                    <InputBox
                      name="name"
                      value={warehouseContext?.warehouse?.name || ""}
                      placeholder={"Magazzino A"}
                      type="text"
                      onChange={handleWarehouseInputChange}
                    />
                  </div>
                  <div className="col">
                    <label className="form-label">Località:</label>
                    <Location
                      service="logistic"
                      callback={(location) => {
                        const warehouseNormalized = warehouseNormalizer(
                          {
                            ...warehouseContext?.warehouse,
                            location: location,
                          },
                          accessTokenPayload
                        );
                        warehouseContext?.editWarehouse(warehouseNormalized);
                      }}
                      defaultLocation={warehouseContext?.warehouse?.location}
                    />
                  </div>
                </div>
              </CardBody>
            </Card>
            <div className="row mt-3">
              <div className="col">
                {warehouseContext?.warehouse?.id && (
                  <React.Fragment>
                    <IndexWarehouseArticlesProvider
                      parentId={warehouseContext?.warehouse?.id}
                    >
                      <WarehouseArticles
                        action={action}
                        shippingRequired={true}
                      />
                    </IndexWarehouseArticlesProvider>
                    <div className="mt-3">
                      <IndexWarehouseArticlesProvider
                        parentId={warehouseContext?.warehouse?.id}
                      >
                        <WarehouseArticles
                          action={action}
                          shippingRequired={false}
                        />
                      </IndexWarehouseArticlesProvider>
                    </div>
                  </React.Fragment>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
