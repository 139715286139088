import React from "react";
import { useOidcAccessToken } from "@axa-fr/react-oidc";
import "../../assets/scss/pages/homepage.scss";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBagsShopping,
  faHandHoldingBox,
  faLongArrowRight,
  faTruckFast,
} from "@awesome.me/kit-c1b85ff10f/icons/classic/light";
import { Card } from "react-bootstrap";

import { CardBody } from "reactstrap";
import Collections from "./Collections";
import { useAppContext } from "../../AppProvider";
import { useTypes } from "../../utilities/types";
import { ReportCard } from "../KPI/ReportCard";
import { ShipmentsProvider } from "../KPI/providers/Shipments";
import moment from "moment";
import { dictionary } from "../../utilities/dictionary";
import { ShipmentsByStatus } from "../KPI/ShipmentsByStatus";
import { ShipmentsByCarrier } from "../KPI/ShipmentsByCarrier";
import { AverageDeliveryTimeForShipmentsPerService } from "../KPI/AverageDeliveryTimeForShipmentsPerService";

const Dashboard = () => {
  const { accessTokenPayload } = useOidcAccessToken();
  const appContext = useAppContext();
  const language = appContext?.language;
  const types = useTypes();
  const shipmentStatus = types.shipmentStatus;
  return (
    <React.Fragment>
      <div className="homepage-header">
        <h1 className="title">
          {dictionary["welcome_back"][language]} {accessTokenPayload.name}!
        </h1>
        <span className="role">{appContext?.owner?.name}</span>
        <div className="rapid-links">
          <Link to={"/shipments"} className="link">
            <FontAwesomeIcon icon={faTruckFast} size="2x" />
            <span>{dictionary["my_shipments"][language].toUpperCase()}</span>
          </Link>
          <Link to={"/orders"} className="link">
            <FontAwesomeIcon icon={faBagsShopping} size="2x" />
            <span>{dictionary["my_orders"][language].toUpperCase()}</span>
          </Link>
          <Link to={"/collections"} className="link">
            <FontAwesomeIcon icon={faHandHoldingBox} size="2x" />
            <span>{dictionary["my_collections"][language].toUpperCase()}</span>
          </Link>
        </div>
      </div>
      <section className="mt-4">
        <h2>{dictionary["overview"][language]}</h2>
        <div className="report-container">
          <ShipmentsProvider
            params={{
              start: moment().startOf("day").format(),
              end: moment().endOf("day").format(),
            }}
          >
            <ReportCard
              title={dictionary["shipments"][language]}
              subtitle={dictionary["today"][language]}
              path={"/shipments"}
              filters={{
                creationDate: [
                  moment().startOf("day").toDate(),
                  moment().endOf("day").toDate(),
                ],
              }}
            />
          </ShipmentsProvider>

          <ShipmentsProvider
            params={{
              start: moment().subtract(1, "months").startOf("day").format(),
              end: moment().endOf("day").format(),
            }}
          >
            <ReportCard
              title={dictionary["shipments"][language]}
              subtitle={dictionary["last_month"][language]}
              path={"/shipments"}
              filters={{
                creationDate: [
                  moment().subtract(1, "months").startOf("day").toDate(),
                  moment().endOf("day").toDate(),
                ],
              }}
            />
          </ShipmentsProvider>
          <ShipmentsProvider
            params={{
              start: moment().subtract(2, "months").startOf("day").format(),
              end: moment().endOf("day").format(),
              statuses: ["EXCEPTION", "FAILED_DLV"],
            }}
          >
            <ReportCard
              title={dictionary["shipments_to_be_verfied"][language]}
              subtitle={dictionary["last_bimonthly"][language]}
              path={"/shipments"}
              filters={{
                creationDate: [
                  moment().subtract(2, "months").startOf("day").toDate(),
                  moment().endOf("day").toDate(),
                ],
                "status.code": [
                  shipmentStatus.find((x) => x.value === "EXCEPTION"),
                  shipmentStatus.find((x) => x.value === "FAILED_DLV"),
                ],
              }}
            />
          </ShipmentsProvider>
        </div>
      </section>
      <section className="mt-3">
        <h2>{dictionary["my_shipments"][language]}</h2>
        <div className="row">
          <div className="col-3">
            <ShipmentsProvider
              params={{
                start: moment().subtract(1, "months").startOf("day").format(),
                end: moment().endOf("day").format(),
                statuses: [
                  "BOOKED",
                  "CONFIRMED",
                  "AVAILABLE",
                  "IN_TRANSIT",
                  "OUT_OF_DLV",
                  "EXCEPTION",
                  "FAILED_DLV",
                  "DELIVERED",
                  "INFO_RCVD",
                ],
              }}
            >
              <ShipmentsByStatus
                subtitle={dictionary["last_month"][language]}
              />
            </ShipmentsProvider>
          </div>
          <div className="col-3">
            <ShipmentsProvider
              params={{
                start: moment().subtract(1, "months").startOf("day").format(),
                end: moment().endOf("day").format(),
                statuses: [
                  "BOOKED",
                  "CONFIRMED",
                  "AVAILABLE",
                  "IN_TRANSIT",
                  "OUT_OF_DLV",
                  "EXCEPTION",
                  "FAILED_DLV",
                  "DELIVERED",
                  "INFO_RCVD",
                ],
              }}
            >
              <ShipmentsByCarrier
                subtitle={dictionary["last_month"][language]}
              />
            </ShipmentsProvider>
          </div>
          <div className="col-6">
            <ShipmentsProvider
              params={{
                start: moment().subtract(1, "months").startOf("day").format(),
                end: moment().endOf("day").format(),
              }}
            >
              <AverageDeliveryTimeForShipmentsPerService
                subtitle={dictionary["last_month"][language]}
              />
            </ShipmentsProvider>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default Dashboard;
